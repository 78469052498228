<template>
  <transition name="fade">
    <div>
      <h2 class='title-cartographie'>Cartographie de l'analyse</h2>

      <!-- Graph Container -->
      <div
        class="graph-container"
        ref="graphContainer"
        id="graph-container"
      >
        <!-- The graph will be rendered inside this div -->
      </div>

      <!-- Side Panels Container -->
      <div class="side-panels" :style="{ width: panelWidth }">
        <!-- Sommaire Panel -->
        <div v-if="isSommairePanelVisible" class="panel sommaire-panel">
          <button @click="closeSommairePanel" class="close-button">&times;</button>

          <!-- Sommaire Content -->
          <div class="sommaire-content" v-if="titlePanel && analysisContent">
            <h3><em>{{ titlePanel }}</em></h3>
            <p>
              <span v-for="(segment, idx) in analysisContent" :key="idx">
                <template v-if="segment.type === 'text'">
                  {{ segment.content }}
                </template>
                <template v-else-if="segment.type === 'link-decision'">
                  <a
                    href="#"
                    @click.prevent="openTextModal(segment.data.texte, segment.data.decisionId, segment.data.title, '')"
                  >
                    <u>{{ segment.content }}</u>
                  </a>
                </template>
                <template v-else-if="segment.type === 'citation'">
                  {{ segment.content }}
                </template>
                <template v-else-if="segment.type === 'bold'">
                  <strong>{{ segment.content }}</strong>
                </template>
                <template v-else-if="segment.type === 'title'">
                  <a href="#" @click.prevent="onTitleClick(segment)">
                    <em><u>{{ segment.content }}</u></em>
                  </a>
                </template>
                <template v-else-if="segment.type === 'bigtitle'">
                  <strong style="font-size: 1.2em">{{ segment.content }}</strong>
                </template>
                <template v-else-if="segment.type === 'url'">
                  <a :href="segment.content" target="_blank" rel="noopener noreferrer">
                    <strong>URL: {{ segment.content }}</strong>
                  </a>
                </template>
                <template v-else-if="segment.type === 'source-link'">
                  <a
                    href="#"
                    @click.prevent="sourceLinkClick(segment.data)"
                    class="source-link"
                  >
                    <u>{{ segment.content }}</u>
                  </a>
                </template>
                <template v-else-if="segment.type === 'decision'">
                  <ul>
                    <li>
                      <a href="#" @click.prevent="onDecisionSegmentClick(segment.data)">
                        {{ segment.content }}
                      </a>
                    </li>
                  </ul>
                </template>
              </span>
            </p>

            <!-- Nested Transition for Additional Content -->
            <transition name="fade">
              <div v-if="contentToDisplay" class="additional-content">
                <p>
                  <span v-for="(segment, idx) in contentToDisplay" :key="idx">
                    <template v-if="segment.type === 'text'">
                      {{ segment.content }}
                    </template>
                    <template v-else-if="segment.type === 'citation'">
                      <u>{{ segment.content }}</u>
                    </template>
                    <template v-else-if="segment.type === 'bold'">
                      <strong>{{ segment.content }}</strong>
                    </template>
                    <template v-else-if="segment.type === 'bigtitle'">
                      <strong style="font-size: 1.1em">{{ segment.content }}</strong>
                    </template>
                    <template v-else-if="segment.type === 'link-decision'">
                      <a
                        href="#"
                        @click.prevent="openTextModal(segment.data.texte, segment.data.decisionId, segment.data.title, '')"
                      >
                        <u>{{ segment.content }}</u>
                      </a>
                    </template>
                    <template v-else-if="segment.type === 'link-euritext'">
                      <a :href="segment.data.decisionId" target="_blank" rel="noopener noreferrer">
                        <strong>Voir document complet: </strong><u>{{ segment.content }}</u>
                      </a>
                    </template>
                  </span>
                </p>
              </div>
            </transition>
          </div>
        </div>

        <!-- Source Panel -->
        <div v-if="isSourcePanelVisible" class="panel source-panel">
          <button @click="closeSourcePanel" class="close-button">&times;</button>

          <!-- Source Content -->
          <div class="source-content" v-if="sourceTitle && sourceTextSegments.length">
            <h3 style="margin-bottom: 1em; font-size: 1.25em;"><em>{{ sourceTitle }}</em></h3>
            <p>
              <span v-for="(segment, idx) in sourceTextSegments" :key="idx">
                <template v-if="segment.type === 'text'">
                  {{ segment.content }}
                </template>
                <template v-else-if="segment.type === 'citation'">
                  <u>{{ segment.content }}</u>
                </template>
                <template v-else-if="segment.type === 'bold'">
                  <strong>{{ segment.content }}</strong>
                </template>
                <template v-else-if="segment.type === 'bigtitle'">
                  <strong style="font-size: 1.1em">{{ segment.content }}</strong>
                </template>
                <template v-else-if="segment.type === 'link-decision'">
                  <a
                    href="#"
                    @click.prevent="openTextModal(segment.data.texte, segment.data.decisionId, segment.data.title, '')"
                  >
                    <u>{{ segment.content }}</u>
                  </a>
                </template>
                <template v-else-if="segment.type === 'link-euritext'">
                  <a :href="segment.data.decisionId" target="_blank" rel="noopener noreferrer">
                    <h3><strong>Voir document complet: </strong></h3><u>{{ segment.content }}</u>
                  </a>
                </template>
              </span>
            </p>
          </div>
        </div>
        <div v-if="isArgDeepenPanelVisible" class="panel deepen-panel">
          <button @click="closeDeepenPanel" class="close-button">&times;</button>
          <div class = "argument-content">
            <p><strong>{{ argumentContent }}</strong></p>
          </div>
          <template v-if="isArgDeepenLoading">
            <div class="loading-spinner"></div>
          </template>
          <template v-else>
            <h3><em>{{ argDeepenQuestion }}</em></h3>
            <p v-if="argDeepenSources.length">
              <strong>Sources :</strong>
              <ul>
                <li v-for="(source, index) in argDeepenSources" :key="index">
                  {{ source }}
                </li>
              </ul>
            </p>
            <p v-else>Aucune source disponible.</p>
          </template>
        </div>
      </div>

      <transition name="fade">
        <!-- Only display the arguments if a point has been selected -->
        <div class="points-accordion" style="margin-top: 2em;" v-if="selectedPoint">
          <div
            class="point-wrapper"
            v-for="(pt, ptIndex) in filteredAnalysesFiscales"
            :key="ptIndex"
          >
            <!-- Always show the point header (the title of the point) -->
            <div class="point-header">
              <span class="point-label">
                {{ pt.point }}
              </span>
            </div>

            <!-- The list of analyses for the selected point -->
            <div class="sommaire-content" v-if="analysisContent">
              <p>
                <span v-for="(segment, idx) in analysisContent" :key="idx">
                  <template v-if="segment.type === 'text'">
                    {{ segment.content }}
                  </template>
                  <template v-else-if="segment.type === 'link-decision'">
                    <a
                      href="#"
                      @click.prevent="openTextModal(segment.data.texte, segment.data.decisionId, segment.data.title, '')"
                    >
                      <u>{{ segment.content }}</u>
                    </a>
                  </template>
                  <template v-else-if="segment.type === 'citation'">
                    {{ segment.content }}
                  </template>
                  <template v-else-if="segment.type === 'bold'">
                    <strong>{{ segment.content }}</strong>
                  </template>
                  <template v-else-if="segment.type === 'title'">
                    <a href="#" @click.prevent="onTitleClick(segment)">
                      <em><u>{{ segment.content }}</u></em>
                    </a>
                  </template>
                  <template v-else-if="segment.type === 'bigtitle'">
                    <strong style="font-size: 1.2em">{{ segment.content }}</strong>
                  </template>
                  <template v-else-if="segment.type === 'url'">
                    <a :href="segment.content" target="_blank" rel="noopener noreferrer">
                      <strong>URL: {{ segment.content }}</strong>
                    </a>
                  </template>
                  <template v-else-if="segment.type === 'source-link'">
                    <a
                      href="#"
                      @click.prevent="sourceLinkClick(segment.data)"
                      class="source-link"
                    >
                      <u>{{ segment.content }}</u>
                    </a>
                  </template>
                  <template v-else-if="segment.type === 'decision'">
                    <ul>
                      <li>
                        <a href="#" @click.prevent="onDecisionSegmentClick(segment.data)">
                          {{ segment.content }}
                        </a>
                      </li>
                    </ul>
                  </template>
                </span>
              </p>
            </div>

            <div class="arg-buttons-container">
              <button
                class="choose-button"
                :class="{ activeButton: isBiasVisible(ptIndex, 'bias_0') }"
                @click="toggleBiasView(ptIndex, 'bias_0')"
                style="margin-right: 10px;"
              >
                Voir arguments - {{ dossierData.analyses_fiscales[ptIndex].biases['bias_0'] }}
              </button>

              <button
                class="choose-button"
                :class="{ activeButton: isBiasVisible(ptIndex, 'bias_1') }"
                @click="toggleBiasView(ptIndex, 'bias_1')"
              >
                Voir arguments - {{ dossierData.analyses_fiscales[ptIndex].biases['bias_1'] }}
              </button>
            </div>

            <!-- Bias 0 list -->
            <transition name="fade">
              <div v-if="isBiasVisible(ptIndex, 'bias_0')" class="arguments-list" style="margin-left: 20px;">
                <ul style="margin-left: 20px; margin-top:10px;">
                  <li
                    v-for="(argObj, argIndex) in getFilteredArgs(pt, 'bias_0')"
                    :key="argObj.id + '-' + argIndex"
                    class="gradient-bg"
                    style="margin-bottom: 1em;"
                  >
                    <!-- Header with toggle arrow and document title -->
                    <div
                      class="analysis-header"
                      style="cursor: pointer; display: flex; align-items: center;"
                      @click="toggleAnalysis(ptIndex, argObj.analysisIndex)"
                    >
                      <span style="margin-left: 8px;">
                        <span class="material-symbols-outlined">
                          {{ isAnalysisExpanded(ptIndex, argObj.analysisIndex) ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                        </span>
                      </span>
                      <div style="margin-left: 8px;">
                        <p>
                          <strong>Document: </strong>
                          <em><u> {{ argObj.title || argObj.textNumber }}</u></em>
                        </p>
                        <!-- If your document has a theme, display it -->
                        <p v-if="argObj.theme">
                          <strong>Thème: </strong> {{ argObj.theme }}
                        </p>
                      </div>
                    </div>

                    <!-- Toggleable argument details -->
                    <transition name="fade">
                      <div v-if="isAnalysisExpanded(ptIndex, argObj.analysisIndex)" class="analysis-details">
                        <p class="argument-field" style="margin-top: 1em; margin-bottom: 1em">
                          <a href="#" @click.prevent="openAnalysisDoc(argObj.data)">
                            <u><em>Voir document</em></u>
                          </a>
                        </p>
                        <p class="argument-field">
                          <strong>Analyse document: </strong> {{ argObj.pertinence_doc }}
                        </p>
                        <p class="argument-field">
                          <strong>Angle d'attaque proposé: </strong> {{ argObj.argument }}
                        </p>
                        <div class="button-line">
                          <button class="deepen-button" @click="approfondirArgument(argObj)">
                            <!-- You can include your SVG icon if desired -->
                            Approfondir cet argument
                          </button>
                          <button class="delete-button" @click="deleteArgument(ptIndex, i)" title="Supprimer cet argument">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="black" class="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
              </div>
            </transition>

            <!-- Bias 1 list (similar structure) -->
            <transition name="fade">
              <div v-if="isBiasVisible(ptIndex, 'bias_1')" class="arguments-list" style="margin-left: 20px;">
                <ul style="margin-left: 20px; margin-top:10px;">
                  <li
                    v-for="(argObj, argIndex) in getFilteredArgs(pt, 'bias_1')"
                    :key="argObj.id + '-' + argIndex"
                    class="gradient-bg"
                    style="margin-bottom: 1em;"
                  >
                    <div
                      class="analysis-header"
                      style="cursor: pointer; display: flex; align-items: center;"
                      @click="toggleAnalysis(ptIndex, argObj.analysisIndex)"
                    >
                      <span style="margin-left: 8px;">
                        <span class="material-symbols-outlined">
                          {{ isAnalysisExpanded(ptIndex, argObj.analysisIndex) ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                        </span>
                      </span>
                      <div style="margin-left: 8px;">
                        <p>
                          <strong>Document:</strong>
                          <em><u>{{ argObj.title || argObj.textNumber }}</u></em>
                        </p>
                        <p v-if="argObj.theme">
                          <strong>Thème:</strong> {{ argObj.theme }}
                        </p>
                      </div>
                    </div>

                    <transition name="fade">
                      <div v-if="isAnalysisExpanded(ptIndex, argObj.analysisIndex)" class="analysis-details">
                        <p class="argument-field" style="margin-top: 1em; margin-bottom: 1em">
                          <a href="#" @click.prevent="openAnalysisDoc(argObj.data)">
                            <u><em>Voir document</em></u>
                          </a>
                        </p>
                        <p class="argument-field">
                          <strong>Note:</strong> {{ argObj.note_arg }}
                        </p>
                        <p class="argument-field">
                          <strong>Analyse document:</strong> {{ argObj.pertinence_doc }}
                        </p>
                        <p class="argument-field">
                          <strong>Angle d'attaque proposé:</strong> {{ argObj.argument }}
                        </p>
                        <div class="button-line">
                          <button class="deepen-button" @click="approfondirArgument(argObj)">
                            Approfondir cet argument
                          </button>
                          <button class="delete-button" @click="deleteArgument(ptIndex, i)" title="Supprimer cet argument">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="black" class="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </transition>
                  </li>
                </ul>
              </div>
            </transition>

          </div>
        </div>
      </transition>



      <!-- Text Modal -->
      <TextModal
        ref="textModal"
        :isVisible="showTextModal"
        :title="modalTitle"
        :text="modalText"
        :citation="modalCitation"
        @close="closeTextModal"
      />
    </div>
  </transition>
</template>


<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { doc, getDoc, collection, getDocs, updateDoc, arrayRemove} from 'firebase/firestore';
import TextModal from './TextModal.vue';
import * as d3 from 'd3';
import { flextree } from 'd3-flextree';
import axios from "axios";

export default {
  props: ['pointsList', 'dossierName'],

  components: {
    TextModal,
  },

  data() {
    return {
      contentToDisplay: '',
      activeArticleId: null,
      dossierData: null,
      modalCitation: "",
      autreAnalysisContent: "",
      activeArticleIdText: null,
      articleTextProcessed: "",
      articleSummaries: {},
      articleText: "",
      articleTitle: "",
      isTextPanelVisible: false,
      selectedSommaireContent: null,
      isSommairePanelVisible: false,
      isSourcePanelVisible: false, // New: Controls Source Panel visibility
      analysisContent: [],
      titlePanel: "",
      articleAnalysisContent: null,
      titleArticle: "",
      articleId: "",
      showTextModal: false,
      modalText: "",
      modalTitle: "",
      decisionAnalysisContent: null,
      titleDecision: "",
      decisionId: "",
      showLink: true,
      visaList: [], // Initialize visaList
      indexLookup: {}, // Initialize indexLookup
      sourceContent: '', // New: Content for Source Panel
      sourceTitle: '',   // New: Title for Source Panel
      sourceTextSegments: [], // New: Parsed segments for Source Content
      selectedPoint: null,
      expandedAnalysis: {}, // existing
      shownBiasViews: {},
      localContext: "",       // Your current context (make sure it’s set elsewhere)
      isArgDeepenPanelVisible: false,
      argDeepenQuestion: "",
      argDeepenSources: [],
      argumentContent: "",
      isArgDeepenLoading: false,
    };
  },

  computed: {
    uniquePoints() {
      return [...new Set(this.pointsList.map(analysis => analysis.point))];
    },
    panelWidth() {
      // If any panel (Sommaire, Source, or Deepen) is open, allocate a width.
      const panelsOpen = [this.isSommairePanelVisible, this.isSourcePanelVisible, this.isArgDeepenPanelVisible].filter(Boolean).length;

      if (panelsOpen === 0) {
        return '0';
      } else if (panelsOpen === 1) {
        return '35%';
      } else if (panelsOpen === 2) {
        return '70%';
      } else {
        return '100%'; // Adjust as needed if more than two are open.
      }
    },
    filteredAnalysesFiscales() {
      if (this.selectedPoint) {
        console.log('selected a point')
        return this.dossierData && this.dossierData.analyses_fiscales
          ? this.dossierData.analyses_fiscales.filter(pt => pt.point === this.selectedPoint)
          : ["caca"];
      }
      return [];
    },

    // (Optionally, you can also create a computed property for the selected point’s data:)
    selectedPointData() {
      if (this.selectedPoint && this.dossierData && this.dossierData.analyses_fiscales) {
        return this.dossierData.analyses_fiscales.find(pt => pt.point === this.selectedPoint);
      }
      return null;
    }
  },

  mounted() {
    this.fetchDossierData();
    console.log('dossierData:', this.dossierData)
  },

  watch: {
    pointsList(newVal) {
      if (newVal && newVal.length > 0) {
        this.createGraph();
      }
    }
  },

  methods: {
    toggleAnalysis(ptIndex, analysisIndex) {
      const key = `${ptIndex}-${analysisIndex}`;
      // Toggle the boolean value; using Vue.set (or this.$set) ensures reactivity
      this.$set(this.expandedAnalysis, key, !this.expandedAnalysis[key]);
    },

    fetchArgDeepen(contexte, point, argument) {
      this.isArgDeepenLoading = true;
      axios
        .post("https://api.query-juriste.com/copilot/get_arg_deepen", {
          contexte: contexte,
          point: point,
          argument: argument
        })
        .then(response => {
          this.isArgDeepenLoading = false;
          if (response.data.error) {
            console.error("Error from backend:", response.data.error);
            return;
          }
          console.log("Deep argument response:", response.data);
          // Set the received question and sources in your component’s state:
          this.argDeepenQuestion = response.data.question;
          this.argDeepenSources = response.data.sources.split(',').map(s => s.trim());
        })
        .catch(error => {
          this.isArgDeepenLoading = false;
          console.error("Error fetching deep argument:", error);
        });
    },

    toggleBiasView(ptIndex, biasId) {
      const otherBiasId = biasId === 'bias_0' ? 'bias_1' : 'bias_0';
      this.$set(this.shownBiasViews, `${ptIndex}-${otherBiasId}`, false);

      const key = `${ptIndex}-${biasId}`;
      this.$set(this.shownBiasViews, key, !this.shownBiasViews[key]);
    },

    isBiasVisible(ptIndex, biasId) {
      const key = `${ptIndex}-${biasId}`;
      return !!this.shownBiasViews[key];
    },

    getFilteredArgs(pointItem, biasId) {
      // pointItem.analyses is an array of docs
      // each doc has an array: doc.arguments
      // so for each doc, we find argument objects where argument.bias_id === biasId

      // We'll build a flattened array of objects combining doc-level
      // and argument-level data:
      const results = [];
      if (!pointItem || !pointItem.analyses) return results;

      pointItem.analyses.forEach((doc, docIndex) => {
        // doc might have doc.arguments = [..., ...]
        if (!doc.arguments || !Array.isArray(doc.arguments)) return;

        // find the argument for the current bias
        const arg = doc.arguments.find(a => a.bias_id === biasId);
        if (arg) {
          results.push({
            data: doc,
            id: doc.id,
            title: doc.title,
            textNumber: doc.textNumber,
            // doc-level fields:
            pertinence_doc: arg.pertinence_doc,
            // argument-level fields:
            bias_id: arg.bias_id,
            argument: arg.argument,
            note_arg: arg.note_arg,
            analysisIndex: docIndex,
          });
        }
      });

      // Now we sort results by descending note_arg
      results.sort((a, b) => (b.note_arg || 0) - (a.note_arg || 0));
      return results;
    },

    /*
    3. “Voir document” or “Approfondir” are optional
        placeholders for your existing logic.
    */
    approfondirArgument(argObj) {
      console.log("approfondirArgument:", argObj);
      // Possibly call an API or open a second-level panel for deeper analysis
    },

    // This method is called (for example) when the user clicks the "Approfondir ce point" button.
    // You can adjust how you retrieve the three parameters based on your data structure.
    approfondirPoint(ptIndex, analysisIndex) {
      // Here we assume:
      // - `localContext` holds the global context,
      // - `selectedPoint` holds the currently selected point,
      // - and the analysis (with an 'argument' property) is stored in your dossierData.
      const contexte = this.localContext;
      const point = this.selectedPoint;
      // Adjust the path below to match how your dossierData is structured:
      const analysis = this.dossierData.analyses_fiscales[ptIndex].analyses[analysisIndex];
      const argument = analysis.argument || "";
      const analyse_doc = analysis.pertinence_doc || "";
      const title_doc = analysis.title || analysis.textNumber;

      const formatted_arg = "**Titre du doument juridique** : " + title_doc + "\n\n" + "**Pertinence du document** : " + analyse_doc + "\n\n" + "**Angle d'attaque proposé** : " + argument;
      this.argumentContent = title_doc;
      console.log("Approfondir ce point avec conetxte:", contexte);
      console.log("Approfondir ce point avec point:", point);
      console.log("Approfondir ce point avec argument:", formatted_arg);

      if (!contexte || !point || !formatted_arg) {
        alert("Données manquantes pour approfondir l'formatted_arg.");
        return;
      }

      this.isArgDeepenPanelVisible = true;
      // Call the Flask endpoint:
      this.fetchArgDeepen(contexte, point, formatted_arg);
    },

    // Closes the deepen panel and clears its content.
    closeDeepenPanel() {
      this.isArgDeepenPanelVisible = false;
      this.argDeepenQuestion = "";
      this.argDeepenSources = [];
    },

     async deleteArgument(analysisIndex) {
      // Confirm user action

      // Get user/dossier references
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;

      // Find the selected point data
      const pointObj = this.dossierData.analyses_fiscales.find(
        (pt) => pt.point === this.selectedPoint
      );
      if (!pointObj) {
        console.error("Point not found for selectedPoint:", this.selectedPoint);
        return;
      }

      // You must have a stored docId or something similar on pointObj
      // For example: pointObj.docId = "point_0"
      const docId = pointObj.id;
      if (!docId) {
        console.error("No docId found on pointObj", pointObj);
        return;
      }

      // Build Firestore doc ref for subcollection doc
      const pointDocRef = doc(
        db,
        "users",
        userId,
        "dossiers",
        dossierId,
        "analyses",
        docId
      );

      // Grab the argument to remove
      const argumentToRemove = pointObj.analyses[analysisIndex];
      console.log("Removing argument:", argumentToRemove);

      // Update Firestore
      if (!confirm("Voulez-vous vraiment supprimer cet argument ?")) return;

      await updateDoc(pointDocRef, {
        analyses: arrayRemove(argumentToRemove)
      });

      // Update local state so UI refreshes
      pointObj.analyses.splice(analysisIndex, 1);
    },

    isAnalysisExpanded(ptIndex, analysisIndex) {
      const key = `${ptIndex}-${analysisIndex}`;
      return !!this.expandedAnalysis[key];
    },

    toggleBias(ptIndex, docIndex, biasId) {
      const key = `${ptIndex}-${docIndex}-${biasId}`;
      this.$set(this.shownBiases, key, !this.shownBiases[key]);
    },
    showingBias(ptIndex, docIndex, biasId) {
      const key = `${ptIndex}-${docIndex}-${biasId}`;
      return !!this.shownBiases[key];
    },

    breakContent(content) {
      return content.replace(/\n/g, '<br>');
    },

    resetPanelData() {
      console.log('Resetting panel data');
      this.contentToDisplay = ''; // Reset contentToDisplay
      this.autreAnalysisContent =  [];
      this.titlePanel = '';
      this.analysisContent = [];
      this.titleArticle = '';
      this.articleId = '';
      this.articleAnalysisContent = [];
      this.titleDecision = '';
      this.decisionId = '';
      this.decisionAnalysisContent = [];
    },

    sourceLinkClick(segmentData) {
      console.log("Source link clicked:", segmentData);
      // Set the source title
      this.sourceTitle = segmentData.title || "Source";

      // Assuming the source content is stored in segmentData.content
      // If your data structure differs, adjust accordingly
      this.sourceTextSegments = this.parseContent(segmentData.content, this.titlePanel);

      // Show the Source Panel
      this.isSourcePanelVisible = true;
    },

    closeSourcePanel() {
      this.isSourcePanelVisible = false;
      this.sourceContent = '';
      this.sourceTitle = '';
      this.sourceTextSegments = [];
    },

    // In your createGraph method
    createGraph() {
      console.log('Creating graph for contentieux...');
      const vm = this;
      const container = this.$refs.graphContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      // Remove existing SVG if it exists
      d3.select(container).select('svg').remove();

      // const wiggleTotalDuration = 1200 + 1200 + 1000; // = 3400 ms
      // const hintDiv = d3.select(container)
      //   .append('div')
      //   .attr('class', 'drag-hint')
      //   .style('position', 'absolute')
      //   .style('color', "lightgrey") // Make sure it's above the graph
      //   .style('top', '8px')
      //   .style('left', '20px')
      //   .style('font-size', '10px')
      //   .style('padding', '6px 10px')
      //   .style('background-color', 'white')
      //   .style('font-family', 'Roboto, sans-serif')
      //   // Add this animation : animation: fade-in 0.8s forwards, wiggle 3.4s ease-in-out infinite;
      //   // Use the "Material Symbols Outlined" icon with text
      //   // The icon name is "drag_click"
      //   .html(`
      //     <span
      //       class="material-symbols-outlined"
      //       style="font-size: 24px; vertical-align: middle; margin-right: 8px;"
      //     >
      //       drag_click
      //     </span>
      //     <span style="vertical-align: middle;">Faites glisser le graphe</span>
      //   `);

      // hintDiv.style('animation', 'wiggle 3.4s ease-in-out infinite');

      // Define the wiggle animation using a <style> tag
      const wiggleAnimation = `
        @keyframes wiggle {
          0% { transform: translateX(0); }
          25% { transform: translateX(5px); } /* Slight movement to the right */
          50% { transform: translateX(-5px); } /* Slight movement to the left */
          75% { transform: translateX(5px); }
          100% { transform: translateX(0); } /* Back to original position */
        }
      `;

      // Append the animation styles to the document head dynamically
      const styleTag = document.createElement('style');
      styleTag.textContent = wiggleAnimation;
      document.head.appendChild(styleTag);

      // Create SVG element
      const svg = d3.select(container)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('preserveAspectRatio', 'xMidYMid meet');

      // Append a group element that will contain all content and be transformed by zoom
      const g = svg.append('g');

      // Define currentTransform
      let currentTransform = d3.zoomIdentity;

      // Define desired spacing between nodes
      const desiredSpacing = 50; // Adjust as needed

      // Define the zoom behavior
      const zoomBehavior = d3.zoom()
        .scaleExtent([1, 1]) // Lock zoom so users can't zoom in/out
        .on("zoom", (event) => {
          g.attr("transform", event.transform);
          currentTransform = event.transform;
        });

      // Define the modified drag behavior
      const dragBehavior = d3.drag()
        .filter(function (event) {
          // Prevent drag if the event target or its ancestors have the 'clickable' class
          let el = event.target;
          while (el) {
            if (el.classList && el.classList.contains('clickable')) {
              return false;
            }
            el = el.parentNode;
          }
          return true;
        })
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded);

      // Apply the zoom behavior to the SVG
      svg.call(zoomBehavior)
        .on("wheel.zoom", null)       // remove wheel-based zoom
        .on("dblclick.zoom", null);

      // Create hierarchical data structure with node dimensions
       const rootData = {
        name: this.dossierName,   // e.g. the top-level name of your dossier
        nodeWidth: 0,
        nodeHeight: 0,
        // For each "point" in analyses_fiscales:
        children: this.dossierData.analyses_fiscales.map((pointData, pointIndex) => {
          // We'll create a rectangular node for the “point” itself:
          const rectWidth = 450;
          const rectHeight = 300;

          // Finally, create the “point” node (the question):
          return {
            name: pointData.point,               // e.g. "Comment déterminer ... filiale ?"
            final_answer: pointData.final_answer,
            id: `point-${pointIndex}`,
            group: 'point',
            nodeWidth: rectWidth,
            nodeHeight: rectHeight,
            // The only child is the “Sommaire” node we constructed
          };
        }),
      };

      // Create a hierarchical layout
      const root = d3.hierarchy(rootData, (d) => d.children);

      // Use d3-flextree for variable node sizes
      const treeLayout = flextree().nodeSize((node) => [
        node.data.nodeWidth + desiredSpacing,
        node.data.nodeHeight + desiredSpacing,
      ]);

      treeLayout(root);

      // Center the tree in the SVG
      const nodes = root.descendants();
      const xMin = d3.min(nodes, (d) => d.x - d.data.nodeWidth / 2);
      const xMax = d3.max(nodes, (d) => d.x + d.data.nodeWidth / 2);
      const yMin = d3.min(nodes, (d) => d.y - d.data.nodeHeight / 2);
      const yMax = d3.max(nodes, (d) => d.y + d.data.nodeHeight / 2);

      const padding = 50; // Adjust as needed
      const maxInitialScale = 1; // Maximum initial scale
      const minInitialScale = 0.5; // Minimum initial scale

      const widthScale = (width - 2 * padding) / (xMax - xMin || 1);
      const heightScale = (height - 2 * padding) / (yMax - yMin || 1);
      let scale = Math.min(widthScale, heightScale);

      // Clamp the initial scale
      scale = Math.max(minInitialScale, Math.min(maxInitialScale, scale));

      const translateX = width / 2 - ((xMin + xMax) / 2) * scale;
      const translateY = padding - yMin * scale;

      // Set initial transform
      currentTransform = d3.zoomIdentity.translate(translateX, translateY).scale(scale);
      g.attr('transform', currentTransform);

      // Apply initial transform to zoom behavior
      svg.call(zoomBehavior.transform, currentTransform);

      // Create links (edges)
      const link = g
        .selectAll('.link')
        .data(root.links())
        .enter()
        .append('path')
        .attr('class', 'link')
        .attr('fill', 'none')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .attr('stroke-width', 2)
        .attr(
          'd',
          d3
            .linkVertical()
            .x((d) => d.x)
            .y((d) => d.y)
        );

      // Create nodes
      const nodeSelection = g
        .selectAll('.node')
        .data(root.descendants())
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (d) => `translate(${d.x},${d.y})`);

      // Apply the drag behavior to nodes
      nodeSelection.call(dragBehavior);

      // Append elements based on node group
      nodeSelection.each(function (d) {
        if (d.depth === 0) {
          // Skip rendering for the dummy root
        } else if (d.data.group === 'point') {
          // Use node dimensions from data
          const rectWidth = d.data.nodeWidth;
          const rectHeight = d.data.nodeHeight;

          // Append a rectangle
          d3.select(this)
            .append('rect')
            .attr('x', -rectWidth / 2) // Center horizontally
            .attr('y', -rectHeight / 2) // Center vertically
            .attr('width', rectWidth)
            .attr('height', rectHeight)
            .attr('fill', '#f4f4f4')
            .attr('rx', 10)
            .attr('ry', 10)
            .attr('class', 'clickable')
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', '#f4f4f4')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
              vm.onPointClick(d);
            });

          // Append text inside the rectangle
          const textGroup = d3
            .select(this)
            .append('text')
            .attr('text-anchor', 'middle')
            .style('font-family', 'Roboto')
            .attr('dominant-baseline', 'middle')
            .attr('x', 0)
            .attr('y', 0)
            .attr('fill', 'black')
            .style('font-size', '20px')
            .style('font-weight', 'light')
            .attr('class', 'clickable')
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', 'black')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
              vm.onPointClick(d);
            });

          // Wrap text into lines
          const words = d.data.name.split(/\s+/);
          const lineHeight = 20;
          const lines = [];
          let currentLine = [];

          words.forEach((word) => {
            const testLine = [...currentLine, word].join(' ');
            const testElement = textGroup.append('tspan').text(testLine);
            const testWidth = testElement.node().getComputedTextLength();
            testElement.remove();

            if (testWidth > rectWidth - 20) {
              lines.push(currentLine.join(' '));
              currentLine = [word];
            } else {
              currentLine.push(word);
            }
          });

          if (currentLine.length) {
            lines.push(currentLine.join(' '));
          }

          lines.forEach((line, i) => {
            textGroup
              .append('tspan')
              .text(line)
              .attr('x', 0)
              .attr('dy', i === 0 ? -((lines.length - 1) * lineHeight) / 2 : lineHeight);
          });
        } else {
          // Use node dimensions from data for circles
          const circleRadius = d.data.nodeWidth / 2;

          d3.select(this)
            .append('circle')
            .attr('r', d.data.name === 'Sommaire' ? circleRadius * 1.2 : circleRadius)
            .attr('class', 'clickable')
            .attr('fill', d.data.name === 'Sommaire' ? '#3498db' : '#1f77b4')
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', d.data.name === 'Sommaire' ? '#3498db' : '#1f77b4')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
              if (d.data.name === 'Sommaire') {
                vm.onReponseClick(d);
              } else {
                vm.onSourceClick(d);
              }
            });

          // Add text inside the circle
          const textElement = d3
            .select(this)
            .append('text')
            .attr('fill', '#fff')
            .attr('class', 'clickable')
            .attr('text-anchor', 'middle')
            .style('font-family', 'Roboto')
            .style('font-weight', 300)
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', '#fff')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
              if (d.data.name === 'Sommaire') {
                vm.onReponseClick(d);
              } else {
                vm.onSourceClick(d);
              }
            });

          // Adjust font size and text wrapping
          let fontSize = d.data.sourceId === 'euritext' ? 13 : 18;
          textElement.style('font-size', fontSize + 'px');

          const maxTextWidth = circleRadius * 2 * 0.8;
          const maxTextHeight = circleRadius * 2 * 0.8;

          let lineCount;
          let textHeight;

          do {
            textElement.text('');
            textElement.style('font-size', fontSize + 'px');
            lineCount = wrapText(textElement, d.data.name, maxTextWidth);

            const bbox = textElement.node().getBBox();
            textHeight = bbox.height;

            if (textHeight > maxTextHeight) {
              fontSize -= 1;
            }
          } while (textHeight > maxTextHeight && fontSize > 6);

          const totalLineHeight = lineCount * fontSize * 1.1;
          const yOffset = -totalLineHeight / 2 + fontSize / 2;

          textElement.selectAll('tspan').attr('y', (_, i) => yOffset + i * fontSize * 1.1);
        }
      });

      // Define drag event handlers
      function dragStarted() {
        d3.select(this).raise().attr('stroke', 'black');
      }

      function dragged(event, d) {
        const dx = event.dx / currentTransform.k;
        const dy = event.dy / currentTransform.k;

        d.x += dx;
        d.y += dy;

        d3.select(this).attr('transform', `translate(${d.x},${d.y})`);

        // Update connected links
        link.attr(
          'd',
          d3
            .linkVertical()
            .x((d) => d.x)
            .y((d) => d.y)
        );
      }

      function dragEnded() {
        d3.select(this).attr('stroke', null);
      }

      function wrapText(textElement, text, maxWidth) {
        textElement.text('');

        const words = text.split(/\s+/);
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.1;
        let tspan = textElement.append('tspan').attr('x', 0).attr('dy', 0);

        for (let i = 0; i < words.length; i++) {
          line.push(words[i]);
          tspan.text(line.join(' '));
          const textLength = tspan.node().getComputedTextLength();

          if (textLength > maxWidth && line.length > 1) {
            line.pop();
            tspan.text(line.join(' '));
            line = [words[i]];
            tspan = textElement
              .append('tspan')
              .attr('x', 0)
              .attr('dy', `${lineHeight}em`)
              .text(words[i]);
            lineNumber++;
          }
        }

        return lineNumber + 1;
      }

      // 1. Compute the final transform that will center the graph
      // (example variables: translateX, translateY, scale)
      const finalTransform = d3.zoomIdentity
        .translate(translateX, translateY)
        .scale(scale);

      // 2. Apply that transform to your group <g> (so the graph is centered)
      g.attr('transform', finalTransform);

      // 3. Also tell d3-zoom that “this is our new current transform”
      svg.call(zoomBehavior.transform, finalTransform);

      // 4. Now define the subtle wiggle using that final transform
      //    We'll move a small distance, then return
      const wiggleDistance = 15; // 5px to the left/right
      // Build brand-new transform objects for each “phase” of the wiggle
      // so we don’t mutate finalTransform itself.
      const wiggleTransform1 = d3.zoomIdentity
        .translate(finalTransform.x + wiggleDistance, finalTransform.y)
        .scale(finalTransform.k);

      const wiggleTransform2 = d3.zoomIdentity
        .translate(finalTransform.x - wiggleDistance, finalTransform.y)
        .scale(finalTransform.k);

      // 5. Chain transitions for a slow, gentle wiggle
      g.transition()
        .duration(1200)           // Move outward slowly
        .ease(d3.easeSinInOut)
        .attr('transform', wiggleTransform1)
        .transition()
        .duration(1200)           // Move back slowly
        .ease(d3.easeSinInOut)
        .attr('transform', wiggleTransform2)
        .transition()
        .duration(1000)           // Return to the original center
        .ease(d3.easeSinInOut)
        .attr('transform', finalTransform)
        .end()
        // .then(() => {
        //   hintDiv.remove();
        // });
    },

    onPointClick(nodeData) {
      // Handle click on point node
      console.log('Point clicked:', nodeData);
      this.resetPanelData();
      // this.closeSourcePanel();
      // this.titlePanel = nodeData.data.name;
      // // For now, set analysisContent to empty or some placeholder
      this.analysisContent = this.parseContent(nodeData.data.final_answer, nodeData.data.name); // or fetch content
      // this.isSommairePanelVisible = true;
      this.selectedPoint = nodeData.data.name;
      this.expandedAnalysis = {};
      console.log('Selected point:', nodeData.data.name);
    },

    onReponseClick(nodeData) {
      console.log('Reponse clicked:', nodeData);
      this.resetPanelData();
      this.closeSourcePanel();
      this.titlePanel = nodeData.data.point;
      this.analysisContent = this.parseContent(nodeData.data.final_answer, nodeData.data.point); // or fetch content
      this.isSommairePanelVisible = true;
      this.selectedPoint = nodeData.data.point;
    },

    openAnalysisDoc(analysis) {
      console.log("Opening document for analysis:", analysis);
      // Reset panel data as needed
      this.resetPanelData();
      this.closeSommairePanel();
      // Set the side panel title to the document title or a default value
      this.titlePanel = analysis.title || analysis.textNumber || "Document";
      // Parse the document content (assuming analysis.content exists)
      const content = analysis.summary || analysis.content || "No content available";
      let segments = this.parseContent(content, this.titlePanel);

      // Prepend a 'link-decision' segment on top of the parsed content.
      // Adjust the data properties as needed based on your data structure.
      if (analysis.source === 'juritext') {
        // Prepend a 'link-decision' segment on top of the parsed content.
        segments.unshift({
          type: 'link-decision',
          content: '\nVoir décision complète\n',
          data: {
            decisionId: analysis.decisionId,    // Or any appropriate ID from your analysis object
            title: (analysis.title || "Document").trim(),
            texte: analysis.texte           // Or use another property containing the full text
          }
        });
      }

      if (analysis.source === 'legiarti') {
        console.log('analysis')
        // Prepend a 'link-decision' segment on top of the parsed content.
        segments.unshift({
          type: 'bold',
          content: '\n' + analysis.hierarchy + '\n\n',
        });
      }

      // Assign the modified segments to analysisContent
      this.analysisContent = segments;

      // Open the side (source) panel
      this.isSommairePanelVisible = true;
    },

    onSourceClick(nodeData) {
      console.log('Source clicked:', nodeData);
      this.resetPanelData();
      this.closeSourcePanel();
      this.titlePanel = nodeData.data.name;
      this.isSommairePanelVisible = true;

      // Get the list of documents
      const documents = nodeData.data.data.analysis;
      // Prepare analysisContent
      this.analysisContent = [];

      documents.forEach((doc) => {
        if (nodeData.data.sourceId === 'juritext') {
          // Handle juritext source
          const titre = doc.title;
          // Each document has title and summary
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n\n',
            data: {
              content: doc.summary,  // Assuming doc.summary contains the summary of the decision
              decisionId: doc.id,
              full_text: doc.texte
            }
          });
        } else if (nodeData.data.sourceId === 'legiarti') {
          console.log('Legiarti doc:', doc.hierarchy);
          // Handle legiarti source
          const titre = doc.textNumber;
          const hierarchyAndContent = doc.hierarchy + '\n\n' + doc.content;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n\n',
            data: {
              content: hierarchyAndContent
            }
          });
        } else if (nodeData.data.sourceId === 'bofip') {
          // Handle bofip source
          const titre = doc.title;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n',
            data: {
              content: doc.content
            }
          });
          this.analysisContent.push({
            type: 'url',
            content: doc.url + '\n\n',
            data: {
              content: doc.content
            }
          });
        } else if (nodeData.data.sourceId === 'euritext') {
          // Handle bofip source
          const titre = doc.title;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n\n',
            links_dict: doc.links,
            data: {
              content: doc.content,
            }
          });
        } else {
          // Handle convbi source or any other fallback
          const titre = doc.title;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n',
            data: {
              content: doc.content
            }
          });
          this.analysisContent.push({
            type: 'url',
            content: doc.url + '\n\n',
            data: {
              content: doc.content
            }
          });
        }
      });
    },

    async fetchDossierData() {
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;
        const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);
        const docSnap = await getDoc(dossierDocRef);

        if (!docSnap.exists()) {
          console.error("Dossier does not exist!");
          return;
        }

        const dossierData = docSnap.data();
        console.log("Fetched dossier caac:", dossierData);
        this.localContext = dossierData.contexte;

        // Check if old structure or new sub-collection
        if (dossierData.analyses_fiscales) {
          // Old structure
          this.dossierData = dossierData;
          this.buildSourceIndexes();
        } else {
          // Possibly new structure in sub-collection "analyses"
          const analysesColRef = collection(dossierDocRef, "analyses");
          const analysesSnap = await getDocs(analysesColRef);
          const analysesFiscales = analysesSnap.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          // Merge
          this.dossierData = {
            ...dossierData,
            analyses_fiscales: analysesFiscales,
          };
        }

        // Now that we have this.dossierData, build the graph
        this.buildSourceIndexes();
        this.createGraph();
      } catch (error) {
        console.error("Error fetching dossier data:", error);
      }
    },

    toggleArguments(index) {
      // If expanded, collapse it; if collapsed, expand it
      const isExpanded = this.expandedPoints.includes(index);
      if (isExpanded) {
        // remove from array
        this.expandedPoints = this.expandedPoints.filter(i => i !== index);
      } else {
        // add to array
        this.expandedPoints.push(index);
      }
    },

    // Called when the user clicks the "Afficher arguments pour X" button
    onShowPointArguments(point) {
      this.selectedPoint = point;
    },

    onTitleClick(segment) {
      // Parse the content using your existing parseContent function
      const parsedContent = this.parseContent(segment.data.content);
      console.log('Parsed content:', segment);
      // Prepend the 'link-decision' segment to the parsed content
      if (segment.data.decisionId) {
        parsedContent.unshift({
          type: 'link-decision',
          content: '\nVoir décision complète\n',
          data: {
            decisionId: segment.data.decisionId,
            title: segment.content.trim(),
            texte: segment.data.full_text,
          },
        });
      } else if (segment.links_dict) {
        // Complete, with for each ite of the dict, key is title, and link is the value, and for each, unshift
        for (const [title, link] of Object.entries(segment.links_dict)) {
          parsedContent.unshift({
            type: 'link-euritext',
            content: title + '\n\n',
            data: {
              decisionId: link,
              title: title,
              texte: '',
            },
          });
        }
      }

      // Set the contentToDisplay to the new array
      this.contentToDisplay = parsedContent;
    },

    async openTextModal(texte, decisionId, title, segment) {
      // const docRef = doc(db, 'cases', decisionId);
      // const docSnap = await getDoc(docRef);
      this.showTextModal = true;
      // if (docSnap.data().texte) {
      //   this.modalText = texte;
      // } else {
      //   this.modalText = 'Texte non disponible.';
      // }
      this.modalText = texte;
      this.modalTitle = title;
      this.modalCitation = segment;

      this.$nextTick(() => {
        if (this.$refs.textModal) {
          this.$refs.textModal.highlightCitation();
        }
      });
    },

    closeTextModal() {
      this.showTextModal = false;
    },

    closeSommairePanel() {
      this.isSommairePanelVisible = false;
      this.selectedSommaireContent = null;
      this.articleAnalysisContent = null;
      this.contentToDisplay = ''; // Reset contentToDisplay
      this.decisionAnalysisContent = null; // Reset decision content
      this.titleDecision = ""; // Reset decision title
      this.decisionId = ""; // Reset decision ID
      // If using unified panel properties
      // this.currentPanelContent = null;
      // this.currentPanelTitle = null;
      // this.currentContentType = null;
    },

    parseContent(text, point_name = '') {
      // 1) First pass: parse formatting only
      const formattedSegments = this.parseFormatting(text);

      // 2) Second pass: expand references in each segment (flattened, no nesting)
      let finalSegments = [];
      for (const seg of formattedSegments) {
        // parseReferences returns an array of segments
        const expanded = this.parseReferences(seg, point_name);
        finalSegments = finalSegments.concat(expanded);
      }

      return finalSegments;
    },

    /**
     * --------------------------------------------------
     * PASS 1: parseFormatting
     * --------------------------------------------------
     * Splits text into segments by:
     *  - "double quotes"
     *  - **bold**
     *  - *italic*
     *  - «French quotes»
     *  - # Title pattern
     *
     * Each segment is { type, content }, content is a string.
     * --------------------------------------------------
     */
    parseFormatting(text) {
      const formatRegex = new RegExp(
        // Group 1: "double quotes"
        '"([^"]*)"' +
          // Group 2: **bold**
          '|\\*\\*([^*]+)\\*\\*' +
          // Group 3: *italic*
          '|\\*([^*]+)\\*' +
          // Group 4: «French quotes»
          '|«([^»]+)»' +
          // Groups 5 & 6: # Title patterns (#, ##, etc.)
          '|(?:^|\\s)(#+\\s)([^\\n]*)',
        'g'
      );

      const segments = [];
      let lastIndex = 0;
      let match;

      while ((match = formatRegex.exec(text)) !== null) {
        // If there is plain text before this match, add it
        if (match.index > lastIndex) {
          segments.push({
            type: 'text',
            content: text.slice(lastIndex, match.index),
          });
        }

        // Identify which group matched
        if (match[1]) {
          // "some text"
          segments.push({
            type: 'citation',
            content: match[1],
          });
        } else if (match[2]) {
          // **bold**
          segments.push({
            type: 'bold',
            content: match[2],
          });
        } else if (match[3]) {
          // *italic*
          segments.push({
            type: 'italic',
            content: match[3],
          });
        } else if (match[4]) {
          // «French quotes»
          segments.push({
            type: 'citation',
            content: match[4],
          });
        } else if (match[5] && match[6]) {
          // # Title
          segments.push({
            type: 'bigtitle',
            content: '\n' + match[6],
          });
        }

        lastIndex = formatRegex.lastIndex;
      }

      // Trailing text after the last match
      if (lastIndex < text.length) {
        segments.push({
          type: 'text',
          content: text.slice(lastIndex),
        });
      }

      return segments;
    },

    buildSourceIndexes() {
      // Initialize the indexLookup object
      const indexLookup = {};

      // Iterate through each point in analyses_fiscales
      this.dossierData.analyses_fiscales.forEach((pointItem) => {
        const pointName = pointItem.point; // The name of the point

        // Initialize the point's sub-structure in indexLookup
        if (!indexLookup[pointName]) {
          indexLookup[pointName] = {};
        }

        // Iterate through analyses within the point
        pointItem.analyses.forEach((doc) => {
          const docIdAnswer = doc.id_answer; // e.g., 'juritext', 'legiarti', 'bofip'
          indexLookup[pointName][docIdAnswer] = doc;

        });
      });

      // Assign the final structure to this.indexLookup
      console.log('Index lookup:', indexLookup);
      this.indexLookup = indexLookup;
    },

    /**
     * --------------------------------------------------
     * PASS 2: parseReferences
     * --------------------------------------------------
     * Splits a single segment into multiple top-level segments,
     * whenever it finds references of the form:
     *    (ID: )? (Jurisprudence | Legiarti | Bofip) - number
     *
     * No nested arrays. The return is always an array
     * of { type, content, [data] } at top-level.
     * --------------------------------------------------
     */
    parseReferences(originalSegment, point_name) {
      // Only segments that might contain references
      // We'll parse text, bold, italic, citation, bigtitle
      // (If you don't want references in bigtitle, remove it)
      const canContain = ['text', 'bold', 'italic', 'citation', 'bigtitle'];
      if (!canContain.includes(originalSegment.type)) {
        // Return unmodified
        return [originalSegment];
      }

      // Now we split the segment’s content by references
      const content = originalSegment.content || '';
      const refRegex = /((?:ID:\s+)?(?:Jurisprudence|Legiarti|Bofip|Euritext)\s*-\s*\d+)/g;

      let lastIndex = 0;
      let match;
      const resultSegments = [];

      while ((match = refRegex.exec(content)) !== null) {
        // If there's text before the match, add it
        if (match.index > lastIndex) {
          const beforeText = content.slice(lastIndex, match.index);
          if (beforeText) {
            // Keep the parent's "type" for these plain chunks.
            // i.e. if the parent was bold, we keep it bold.
            resultSegments.push({
              type: originalSegment.type,
              content: beforeText,
            });
          }
        }

        // We found a reference
        const referenceStr = match[1]; // e.g. "ID: Legiarti - 1"
        const refMatch = referenceStr.match(/^(?:ID:\s+)?(Jurisprudence|Legiarti|Bofip|Euritext)\s*-\s*(\d+)$/);
        console.log('referenceStr:', referenceStr);
        console.log('refMatch:', refMatch);
        if (refMatch) {
          const refType = refMatch[1]; // "Jurisprudence", "Legiarti", or "Bofip"
          const reference = refMatch[1] + ' - ' + refMatch[2];

          // Map to your actual source keys
          let sourceKey = null;
          if (refType === 'Jurisprudence') sourceKey = 'juritext';
          else if (refType === 'Legiarti') sourceKey = 'legiarti';
          else if (refType === 'Bofip') sourceKey = 'bofip';
          else if (refType === 'Euritext') sourceKey = 'euritext';

          console.log('sourceKey:', sourceKey);
          console.log('point_name:', point_name);
          console.log('this.indexLookup:', this.indexLookup);
          console.log('this.indexLookup[point_name]:', this.indexLookup[point_name]);

          // Try to find the doc in indexLookup
          if (
            sourceKey &&
            this.indexLookup &&
            this.indexLookup[point_name]
          ) {
            const docFound = this.indexLookup[point_name][reference];
            if (docFound) {
              // Found a doc => create a source-link
              const docTitle = docFound.title || docFound.textNumber || `Document #${reference}`;
              resultSegments.push({
                type: 'source-link',
                content: docTitle,
                data: {
                  sourceId: sourceKey,
                  id: docFound.id,
                  content: docFound.summary || docFound.content,
                  full_text: docFound.texte || docFound.summary,
                  source: sourceKey,
                  title: docTitle,
                },
              });
            } else {
              // If no doc found => fallback to text
              resultSegments.push({
                type: 'text',
                content: referenceStr,
              });
            }
          } else {
            // Not recognized or not found
            resultSegments.push({
              type: 'text',
              content: referenceStr,
            });
          }
        } else {
          // If for some reason the sub-regex fails
          resultSegments.push({
            type: 'text',
            content: referenceStr,
          });
        }

        lastIndex = refRegex.lastIndex;
      }

      // Append the trailing text after last match
      if (lastIndex < content.length) {
        const trailingText = content.slice(lastIndex);
        if (trailingText) {
          resultSegments.push({
            type: originalSegment.type,
            content: trailingText,
          });
        }
      }

      // Return an array of top-level segments, no nesting
      return resultSegments;
    },

  }
};
</script>

<style scoped>

.choose-button.activeButton {
  color: #3498db; /* any highlight color you prefer */
  border-color: #2980b9;     /* optionally tweak border */
}

.arg-buttons-container {
  display: flex;
  justify-content: center;
  margin: 1em;
  margin-bottom: 1.8em;
}

.loading-spinner {
  margin: 20px auto;
  border: 8px solid #f3f3f3;      /* Light grey */
  border-top: 8px solid #3498db;   /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.deepen-panel {
  width: 35vw;
  height: 100%;
  background-color: #eddcd1; /* Similar background to your Source Panel */
  border-left: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1000;
}

.deepen-content h3 {
  margin-bottom: 1em;
  font-size: 1.25em;
  font-family: "Roboto", sans-serif;
}

.deepen-content ul {
  list-style: none;
  padding: 0;
}

.deepen-content li {
  padding: 5px 0;
  font-size: 0.9rem;
}

.choose-button {
  background-color: white;
  display: flex;
  color: black;
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.85rem;
  margin-top: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.deepen-button {
  display: none !important;
  background-color: white;
  display: flex;
  color: black;
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.85rem;
  margin-top: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.deepen-button:hover {
  background-color: lightgray;
}

.button-line {
  margin: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gradient-bg {
  /* A horizontal gradient that is transparent at the edges and solid in the center */
  background: #f4f4f4;             /* A soft beige background */
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  padding: 1em;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.gradient-bg:hover {
  transform: translate(0px, -10px); /* Moves the element 10px right and 10px up on hover */
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.9s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.argument-field {
  margin: 1em;
}

.points-accordion {
  margin-top: 1em;
}

.point-wrapper {
  margin-bottom: 10px;
  border-radius: 5px;
}

/* This is the clickable row for each point */
.point-header {
  display: flex;
  align-items: center;
  padding: 0.75em;
  color: black;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
}

/* Arrow, e.g. ▲ or ▼ */
.point-header .arrow {
  font-weight: bold;
}

/* The label (pt.point) next to the arrow */
.point-header .point-label {
  font-style: italic;
  padding: 1em;
  background-color: #f4f4f4;
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  border-radius: 15px;
  font-size: 1.2em;
}

/* The arguments container */
.point-arguments {
  padding: 0.75em;
  margin: 1em;
  border-radius: 0 0 5px 5px;
}

/* Fade transition for argument expansion */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  max-height: 0;
  transform: translateY(-10px);
}

.additional-content {
  padding-top: 25px;
  border-top: 1px solid black;
}

.source-content{
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}

.side-panels {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row; /* Stack panels horizontally */
  height: 100%;
  z-index: 1000; /* Ensure panels are above other content */
}

#graph-container {
  width: 100%;
  height: 235px;
  margin-left: 0;
  margin-right: 0;
  transition: margin 0.3s ease;
}

.graph-container.with-sommaire-panel {
  border-left:1px solid #ccc;
  margin-left:35%; /* Space for Sommaire Panel on the left */
}

.graph-container.with-source-panel {
  margin-right:35%; /* Space for Source Panel on the right */
}

.graph-container.with-sommaire-panel.with-source-panel {
  margin-left:35%;
  margin-right:35%;
}

/* Source Panel Styling */
/* Sommaire Panel Styling */
.sommaire-panel {
  /* position: fixed;
  top: 0;
  right: 0;
  left: auto; */
  border-left:1px solid #ccc;
  width: 35vw;
  height: 100%;
  background-color: #f5efeb; /* Same background color */
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1000;
}

/* Source Panel Styling */
.source-panel {
  /* position: fixed;
  top: 0;
  right: 0; */
  height: 100%;
  background-color: #f5efeb; /* Same background color */
  border-left:1px solid #ccc; /* Border between panels */
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1000;
}

.drag-hint {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 12px;
  background-color: transparent; /* No background color */
  font-family: 'Roboto', sans-serif;
  color: lightgrey; /* Set text color to light grey */
  z-index: 10; /* Make sure it's above the graph */
  display: flex;
  align-items: center;
  animation: fade-in 0.8s forwards; /* Optional fade-in effect */
}

.drag-hint .material-symbols-outlined {
  font-size: 24px;
  color: lightgrey; /* Icon color to match the text */
  margin-right: 8px;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px); /* Slight movement to the right */
  }
  50% {
    transform: translateX(-5px); /* Slight movement to the left */
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0); /* Back to original position */
  }
}

#decision-title-panel {
  margin-bottom: 5px;
}

.decision-link {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

#decision-analysis {
  margin-top: 50px;
  border-top: #333;
}

.title-cartographie {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.fade-enter-active{
  transition: clip-path 1s ease-out, opacity 1s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-enter {
  opacity: 0;
  clip-path: inset(0 0 100% 0); /* Start with the element fully clipped at the bottom */
}

.fade-leave-active {
  transition: clip-path 0.8s ease-out, opacity 0.8s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
  clip-path: inset(100% 0 0 0); /* Start with the element fully clipped at the bottom */
}

.point-title {
  font-size: 1.7rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.sommaire-columns {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sommaire-section {
  flex: 1 1 45%; /* Adjust the percentage as needed */
  border-radius: 15px;
  padding: 7px;
  margin: 10px;
  box-sizing: border-box;
}

.sommaire-section h4 {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.sommaire-section p {
  font-size: 1em;
  line-height: 1.4;
  text-align: justify;
}

@media (max-width: 600px) {
  .sommaire-section {
    flex: 1 1 100%;
  }
}


.summary {
  margin-bottom: 20px;
  height: 100%;
}

.summary ul {
  list-style: none;
  padding: 0;
}

.summary li {
  display: inline-block;
  margin: 0 10px;
}

.summary a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.summary a:hover {
  text-decoration: underline;
}

.text-panel {
  background-color: #f4f4f4;
  overflow-y: auto;
  overflow-x: hidden;
  position:relative;
  width: 50%;
  display: block;
  padding: 20px;
  border: 1px solid #ccc;
  max-height: 100vh;
}

.texte-pertinence {
  margin: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
}

.half-width {
  width: 50%!important;
}

.case-text {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 0.8em;
  font-family: 'Roboto', sans-serif;
}

.citation-highlight {
  background-color: yellow;
  display: inline-block;
}

.card-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#modal-content-id {
  background-color: white;
  width: 82%;
  padding: 30px;
  border-radius: 25px;
  overflow-x: hidden;
  position: relative;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  float: right;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor:pointer;
  position: absolute;
  top: 25px;
  right: 25px;
}

h2, h3 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
}

#autre-analysis {
  border-top: 0px solid #ccc !important;
}

li {
  padding: 10px;
}

li:last-child {
  border-bottom: none;
}

.summary ul ul {
  margin-left: 20px;
  list-style-type: disc;
}

.summary ul ul li {
  display: block; /* Makes each item take the full width */
  margin: 5px 0;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Keeps the text within the bounds of the li element */
  text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
}

.summary ul ul li a {
  font-weight: normal;
  color: #555;
  font-size: 0.9rem;
}

.summary ul ul li a:hover {
  text-decoration: underline;
}


.links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.labels text {
  pointer-events: none;
  user-select: none;
}

.sommaire-content {
  width: 100%;
  margin-top: 30px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  background: #f4f4f4;             /* A soft beige background */
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  padding: 1em;
  border-radius: 15px;
}


.sommaire-content h3 {
  margin-bottom: 25px;
  font-size: 1.25rem;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  background: none;
}

.clickable {
  cursor: pointer; /* Changes cursor to pointer on hover */
  pointer-events: all; /* Ensures the element can receive pointer events */
}

</style>
