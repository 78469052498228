<template>
  <div class="dossier-page bg-light">
    <SyntheseModal
      :isVisible="showSyntheseModal"
      :context="searchQuery"
      :dossierId="$route.params.dossierId"
      @startAnalysis="startAnalysis"
      @close="showSyntheseModal = false"
    />

    <header class="page-header">
      <h2 style="color: #1c1c1a"></h2>
      <h2 style="color: black; font-size: 1.5rem; font-weight: 150;">{{ dossierName }}</h2>
      <h2 style="color: #1c1c1a"></h2>
    </header>

    <div v-if="loading" class="loading">
      <button style="display: flex" @click="goDashboard">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          style="margin-right: 5px; margin-top: 4px"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0
               1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1
               16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147
               2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0
               0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707
               7.5z"
          />
        </svg>
        Retour
      </button>
      Chargement des décisions sauvegardées...
    </div>

    <div v-else>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div v-else class="main-container">
        <div class="search-results" id="action-container">
          <button style="display: flex; margin-bottom: 20px; align-self: flex-start" @click="goDashboard">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left-circle"
              viewBox="0 0 16 16"
              style="margin-right: 5px; margin-top: 4px"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14
                   0A7 7 0 0 0 1 8m15 0A8 8 0 1 1
                   0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5
                   0 0 1 0 1H5.707l2.147 2.146a.5.5
                   0 0 1-.708.708l-3-3a.5.5 0 0
                   1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707
                   7.5z"
              />
            </svg>
            Retour
          </button>
          <div class = "title-container">
            <h3 class="message-title" v-if="isAnalysing != 'pending' && isAnalysing != 'done'">Que recherchez-vous aujourd'hui ?</h3>
          </div>
          <form class="search-form">
            <div class="input-container">
              <textarea
                v-model="searchQuery"
                :class="{'error-input': searchError}"
                placeholder="Mots-clés, question(s), contexte juridique..."
                class="search-input"
                rows="1"
                ref="searchBox"
                @input="adjustTextareaHeight"
              ></textarea>
            </div>

            <div class="buttons-container" :style="{ justifyContent: allowedContentieux ? 'space-between' : 'flex-end' }">
              <div class = "example-container" id="contentieux-trigger-container" v-if="allowedContentieux">
                <button
                  class="example-button"
                  id="contentieux-trigger-button"
                  type="button"
                  :class="{
                    'toggle-button-active': contentieuxMode,
                    'toggle-button-disabled': isAnalysing === 'pending',
                    'toggle-button-none': isAnalysing === 'done'
                     }"

                  style="display: inline-flex; align-items: center;"
                  @click="toggleContentieux"
                >
                  <span class="material-symbols-outlined" style="font-size: 14px; margin-top: 1px; margin-right: 3px;">
                    swords
                  </span>
                  Contentieux

                  <div class="switch" style="margin-left: 8px;">
                    <!--
                      @click.stop ensures the click on the checkbox
                      doesn’t bubble to the button’s @click,
                      preventing double toggles
                    -->
                    <input
                      type="checkbox"
                      :checked="contentieuxMode"
                      @click.stop="toggleContentieux"
                    />
                    <span class="slider"></span>
                  </div>
                </button>
              </div>
              <div v-if="isAnalysing != 'done'" class="buttons-line">
                <button
                  type="button"
                  @click="handleAnalysisButtonClick"
                  :class="{'enrichir-button': true, 'pending-analysis': isAnalysing === 'pending'}"
                  :disabled="isAnalysing === 'pending'"
                  style="display: flex; align-items: center;"
                >
                  <template v-if="isAnalysing === 'pending'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="spinner-icon"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style="margin-right: 5px;"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546
                           2.916.5.5 0 1 1 .908-.418A4 4 0
                           1 0 8 4V1l3 3-3 3V4z"
                      />
                    </svg>
                    <p>Analyse en cours</p>
                  </template>
                  <template v-else-if="isAnalysing === 'done'">
                    <!-- If analysis is done, handle differently if needed -->
                  </template>
                  <template v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#1c1c1a"
                      class="bi bi-arrow-up-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                    </svg>
                  </template>
                </button>
              </div>
            </div>
          </form>
          <div v-if="isAnalysing === 'pending'" class="progress-bar-container">
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="{ width: progressPercentage + '%' }"
                :aria-valuenow="progressPercentage"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ Math.round(progressPercentage) }}%
              </div>
            </div>
            <div class="analysis-message loading-message" style="margin-top: 10px;">
              <div class="title-points">
                  <p><em>{{ analysisMessage }}</em></p>
              </div>
            </div>
          </div>
          <transition name="fade">
            <!-- Display the example button when not loading and search query is empty -->
            <div v-if="searchQuery === ''" class = "example-container">
              <button
                @click="goToLibrary"
                class="example-button"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16" style="margin-top: 2px; margin-right: 5px;">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
              </svg>
                Comment utiliser Query Juriste ?
              </button>
            </div>
          </transition>

          <div class="graph">
            <contentieux-modal
              v-if="isAnalysing === 'done' && dossierType === 'contentieux'"
              ref="ContentieuxModal"
              :pointsList="analyses"
              :dossierName="dossierName"
            />
            <analysis-modal
              v-else-if="isAnalysing === 'done'"
              ref="analysisModal"
              :pointsList="analyses"
              :dossierName="dossierName"
            />
          </div>
        </div>
      </div>
    </div>

    <TextModal
      :isVisible="showTextModal"
      :title="modalTitle"
      :text="modalText"
      @close="closeTextModal"
    />
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore";
import TextModal from "./TextModal.vue";
import AnalysisModal from "./AnalysisModal.vue";
import ContentieuxModal from "./ContentieuxModal.vue";
import axios from "axios";
import { getFirebaseToken } from "@/services/api";
import SyntheseModal from "./SyntheseModal.vue";
import { onSnapshot } from "firebase/firestore";

export default {
  name: "DossierPage",
  components: {
    TextModal,
    AnalysisModal,
    SyntheseModal,
    ContentieuxModal,
  },
  data() {
    return {
      // Define the questions array
      questions: [
        // Droit des affaires
        {
          id: 'rupture_contrat_distribution',
          text: 'Une entreprise souhaite rompre un contrat de distribution exclusive avant son terme en invoquant une faute grave du distributeur. Quels sont les critères pour établir cette faute grave et éviter une indemnisation ?',
          specifications: [
            {
              id: 'droit_affaires_moyenne',
              title: 'Droit des affaires - Question contextuelle moyenne :',
              description: 'Une entreprise souhaite rompre un contrat de distribution exclusive avant son terme en invoquant une faute grave du distributeur. Quels sont les critères pour établir cette faute grave et éviter une indemnisation ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'abus_position_dominante',
          text: 'Une société française exporte régulièrement ses produits vers l\'Union européenne. Elle a récemment été accusée d\'abus de position dominante par un concurrent, qui soutient qu\'elle impose des prix prédateurs à ses clients dans certains États membres. Quels éléments de preuve et critères juridiques le concurrent devra-t-il apporter pour que cette accusation soit fondée selon le droit de la concurrence européen ?',
          specifications: [
            {
              id: 'droit_affaires_longue',
              title: 'Droit des affaires - Question longue et détaillée :',
              description: 'Une société française exporte régulièrement ses produits vers l\'Union européenne. Elle a récemment été accusée d\'abus de position dominante par un concurrent, qui soutient qu\'elle impose des prix prédateurs à ses clients dans certains États membres. Quels éléments de preuve et critères juridiques le concurrent devra-t-il apporter pour que cette accusation soit fondée selon le droit de la concurrence européen ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'conflit_earn_out_LBO',
          text: 'Une société de capital-investissement acquiert une entreprise familiale via un LBO (leveraged buyout). Après la transaction, un conflit émerge avec les actionnaires fondateurs concernant les modalités de calcul de l\'earn-out prévu dans le contrat. Quels sont les leviers juridiques pour trancher ce litige et protéger les intérêts de l’acquéreur ?',
          specifications: [
            {
              id: 'droit_affaires_complexe',
              title: 'Droit des affaires - Cas complexe et très contextuel :',
              description: 'Une société de capital-investissement acquiert une entreprise familiale via un LBO (leveraged buyout). Après la transaction, un conflit émerge avec les actionnaires fondateurs concernant les modalités de calcul de l\'earn-out prévu dans le contrat. Quels sont les leviers juridiques pour trancher ce litige et protéger les intérêts de l’acquéreur ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'litige_confidentialite_startup',
          text: 'Une startup technologique a récemment obtenu un financement important auprès de plusieurs investisseurs en capital-risque. Cependant, l’un des investisseurs souhaite désormais se retirer, invoquant une violation de la clause de confidentialité dans le pacte d’actionnaires, après qu’une discussion stratégique ait été relayée dans la presse. Quels mécanismes contractuels et juridiques peuvent être utilisés pour résoudre ce litige, tout en préservant la stabilité du tour de financement et des relations entre les parties ?',
          specifications: [
            {
              id: 'droit_affaires_pratique',
              title: 'Droit des affaires - Cas pratique structuré et multidimensionnel :',
              description: 'Une startup technologique a récemment obtenu un financement important auprès de plusieurs investisseurs en capital-risque. Cependant, l’un des investisseurs souhaite désormais se retirer, invoquant une violation de la clause de confidentialité dans le pacte d’actionnaires, après qu’une discussion stratégique ait été relayée dans la presse. Quels mécanismes contractuels et juridiques peuvent être utilisés pour résoudre ce litige, tout en préservant la stabilité du tour de financement et des relations entre les parties ?'
            }
          ],
          category: 'Droit des affaires'
        },

        // Droit fiscal
        {
          id: 'credit_impot_recherche',
          text: 'Une entreprise souhaite bénéficier d\'un crédit d\'impôt pour dépenses de recherche (CIR). Quels types de dépenses sont éligibles et comment doivent-elles être justifiées auprès de l\'administration fiscale ?',
          specifications: [
            {
              id: 'droit_fiscal_moyenne',
              title: 'Droit fiscal - Question contextuelle moyenne :',
              description: 'Une entreprise souhaite bénéficier d\'un crédit d\'impôt pour dépenses de recherche (CIR). Quels types de dépenses sont éligibles et comment doivent-elles être justifiées auprès de l\'administration fiscale ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'plus_value_immobilier',
          text: 'Un contribuable personne physique a réalisé une plus-value importante suite à la vente d’un bien immobilier non affecté à son habitation principale. Quels sont les abattements applicables pour durée de détention, et comment est calculée l\'imposition sur cette plus-value dans le cadre de l\'impôt sur le revenu et des prélèvements sociaux ?',
          specifications: [
            {
              id: 'droit_fiscal_longue',
              title: 'Droit fiscal - Question longue et détaillée :',
              description: 'Un contribuable personne physique a réalisé une plus-value importante suite à la vente d’un bien immobilier non affecté à son habitation principale. Quels sont les abattements applicables pour durée de détention, et comment est calculée l\'imposition sur cette plus-value dans le cadre de l\'impôt sur le revenu et des prélèvements sociaux ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'depenses_somptuaires_pme',
          text: 'Une PME française ayant opté pour le régime réel simplifié a récemment fait l\'objet d\'une vérification de comptabilité. L\'administration fiscale conteste l\'inscription de certaines charges en les qualifiant de dépenses somptuaires non déductibles. Quels critères permettent de déterminer si une dépense est déductible, et quels recours l’entreprise peut-elle engager face à une rectification fiscale ?',
          specifications: [
            {
              id: 'droit_fiscal_complexe',
              title: 'Droit fiscal - Cas complexe et très contextuel :',
              description: 'Une PME française ayant opté pour le régime réel simplifié a récemment fait l\'objet d\'une vérification de comptabilité. L\'administration fiscale conteste l\'inscription de certaines charges en les qualifiant de dépenses somptuaires non déductibles. Quels critères permettent de déterminer si une dépense est déductible, et quels recours l’entreprise peut-elle engager face à une rectification fiscale ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'transmission_parts_sociales',
          text: 'Une société familiale exploitant un commerce souhaite transmettre ses parts sociales à la génération suivante tout en limitant l’impact fiscal de cette opération. Quels mécanismes, tels que le pacte Dutreil, peuvent être utilisés pour réduire les droits de mutation, et quelles sont les conditions juridiques et fiscales à respecter pour en bénéficier ?',
          specifications: [
            {
              id: 'droit_fiscal_pratique',
              title: 'Droit fiscal - Cas pratique structuré et multidimensionnel :',
              description: 'Une société familiale exploitant un commerce souhaite transmettre ses parts sociales à la génération suivante tout en limitant l’impact fiscal de cette opération. Quels mécanismes, tels que le pacte Dutreil, peuvent être utilisés pour réduire les droits de mutation, et quelles sont les conditions juridiques et fiscales à respecter pour en bénéficier ?'
            }
          ],
          category: 'Droit fiscal'
        },

        // Droit de la famille
        {
          id: 'prestation_compensatoire',
          text: 'Un couple marié sous le régime de la séparation de biens souhaite divorcer. L’un des conjoints réclame une prestation compensatoire. Quels éléments seront pris en compte par le juge pour décider de l’attribution et du montant de cette prestation ?',
          specifications: [
            {
              id: 'droit_famille_moyenne',
              title: 'Droit de la famille - Question contextuelle moyenne :',
              description: 'Un couple marié sous le régime de la séparation de biens souhaite divorcer. L’un des conjoints réclame une prestation compensatoire. Quels éléments seront pris en compte par le juge pour décider de l’attribution et du montant de cette prestation ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'demenagement_parent',
          text: 'Deux parents divorcés partagent l\'autorité parentale d\'un enfant mineur. L\'un des parents souhaite déménager dans une autre région pour des raisons professionnelles, ce qui impacterait les modalités de garde. Quels sont les recours juridiques de l\'autre parent pour s’opposer à ce déménagement et maintenir un droit de visite régulier ?',
          specifications: [
            {
              id: 'droit_famille_longue',
              title: 'Droit de la famille - Question longue et détaillée :',
              description: 'Deux parents divorcés partagent l\'autorité parentale d\'un enfant mineur. L\'un des parents souhaite déménager dans une autre région pour des raisons professionnelles, ce qui impacterait les modalités de garde. Quels sont les recours juridiques de l\'autre parent pour s’opposer à ce déménagement et maintenir un droit de visite régulier ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'indemnisation_maison_separation',
          text: 'Un couple non marié s’est séparé après 10 ans de vie commune. Ils ont acheté une maison ensemble, mais seul l’un d’entre eux figure sur l’acte de propriété. Après leur séparation, l’autre demande une indemnisation pour avoir contribué au remboursement du crédit et à l’entretien de la maison. Quels sont les moyens juridiques pour établir une compensation financière dans ce cas ?',
          specifications: [
            {
              id: 'droit_famille_complexe',
              title: 'Droit de la famille - Cas complexe et très contextuel :',
              description: 'Un couple non marié s’est séparé après 10 ans de vie commune. Ils ont acheté une maison ensemble, mais seul l’un d’entre eux figure sur l’acte de propriété. Après leur séparation, l’autre demande une indemnisation pour avoir contribué au remboursement du crédit et à l’entretien de la maison. Quels sont les moyens juridiques pour établir une compensation financière dans ce cas ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'dettes_communautaires_divorce',
          text: 'Une femme découvre que son époux, avec lequel elle est mariée sous le régime de la communauté, a contracté plusieurs dettes importantes sans son consentement dans le cadre de son activité professionnelle. Suite à une procédure de divorce, elle s’inquiète des conséquences de ces dettes sur son patrimoine personnel. Quels sont les mécanismes de protection prévus par le droit pour limiter l’impact de ces dettes sur sa part de la communauté ? Quelles options s’offrent à elle pour contester sa responsabilité dans le remboursement de ces dettes ?',
          specifications: [
            {
              id: 'droit_famille_pratique',
              title: 'Droit de la famille - Cas pratique structuré et multidimensionnel :',
              description: 'Une femme découvre que son époux, avec lequel elle est mariée sous le régime de la communauté, a contracté plusieurs dettes importantes sans son consentement dans le cadre de son activité professionnelle. Suite à une procédure de divorce, elle s’inquiète des conséquences de ces dettes sur son patrimoine personnel. Quels sont les mécanismes de protection prévus par le droit pour limiter l’impact de ces dettes sur sa part de la communauté ? Quelles options s’offrent à elle pour contester sa responsabilité dans le remboursement de ces dettes ?'
            }
          ],
          category: 'Droit de la famille'
        },

        // Droit du travail
        {
          id: 'heures_sup_non_remunerees',
          text: 'Un salarié réclame le paiement d\'heures supplémentaires non rémunérées, mais l\'employeur conteste leur réalisation. Quels éléments le salarié doit-il apporter pour prouver qu’il a effectivement effectué ces heures ?',
          specifications: [
            {
              id: 'droit_travail_moyenne',
              title: 'Droit du travail - Question contextuelle moyenne :',
              description: 'Un salarié réclame le paiement d\'heures supplémentaires non rémunérées, mais l\'employeur conteste leur réalisation. Quels éléments le salarié doit-il apporter pour prouver qu’il a effectivement effectué ces heures ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'clause_non_concurrence',
          text: 'Un employeur souhaite mettre en place une clause de non-concurrence pour certains salariés après leur départ. Quels sont les critères de validité de cette clause en droit du travail français, et quelles conséquences en cas de non-respect par l\'employeur ?',
          specifications: [
            {
              id: 'droit_travail_longue',
              title: 'Droit du travail - Question longue et détaillée :',
              description: 'Un employeur souhaite mettre en place une clause de non-concurrence pour certains salariés après leur départ. Quels sont les critères de validité de cette clause en droit du travail français, et quelles conséquences en cas de non-respect par l\'employeur ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'licenciement_apres_burn_out',
          text: 'Un salarié a été placé en arrêt maladie pendant plusieurs mois suite à un burn-out. Pendant cette période, l’employeur a réorganisé l’entreprise, supprimant le poste du salarié pour motif économique. Ce dernier conteste son licenciement, estimant que l’employeur a abusé de la situation de fragilité liée à sa maladie. Quels sont les risques juridiques pour l’employeur, et comment justifier la réalité et le sérieux du motif économique ?',
          specifications: [
            {
              id: 'droit_travail_complexe',
              title: 'Droit du travail - Cas complexe et très contextuel :',
              description: 'Un salarié a été placé en arrêt maladie pendant plusieurs mois suite à un burn-out. Pendant cette période, l’employeur a réorganisé l’entreprise, supprimant le poste du salarié pour motif économique. Ce dernier conteste son licenciement, estimant que l’employeur a abusé de la situation de fragilité liée à sa maladie. Quels sont les risques juridiques pour l’employeur, et comment justifier la réalité et le sérieux du motif économique ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'reorganisation_temps_travail',
          text: 'Une entreprise souhaite modifier l’organisation du temps de travail en passant d’un horaire fixe à une annualisation du temps de travail pour ses employés. Certains salariés refusent cette modification, arguant qu\'elle change substantiellement leur contrat de travail. Quels sont les moyens légaux pour l’employeur de mettre en place cette réorganisation, tout en respectant les droits des salariés et les obligations conventionnelles ? Quels recours ces derniers peuvent-ils engager en cas de désaccord ?',
          specifications: [
            {
              id: 'droit_travail_pratique',
              title: 'Droit du travail - Cas pratique structuré et multidimensionnel :',
              description: 'Une entreprise souhaite modifier l’organisation du temps de travail en passant d’un horaire fixe à une annualisation du temps de travail pour ses employés. Certains salariés refusent cette modification, arguant qu\'elle change substantiellement leur contrat de travail. Quels sont les moyens légaux pour l’employeur de mettre en place cette réorganisation, tout en respectant les droits des salariés et les obligations conventionnelles ? Quels recours ces derniers peuvent-ils engager en cas de désaccord ?'
            }
          ],
          category: 'Droit du travail'
        }
      ],
      cases: [],
      dossierName: "",
      dossierType: null,
      showSyntheseModal: false,
      loading: true,
      error: null,
      activeCaseId: null,
      caseSummaries: {},
      showTextModal: false,
      showModal: false,
      analyses: [],
      modalText: "",
      modalTitle: "",
      searchQuery: "",
      searchError: false,
      isAnalysing: "null", // 'pending', 'done', or null
      progressPercentage: 0,
      progressInterval: null,
      // Optional questionId parameter:
      questionId: this.$route.params.questionId || null,
      contentieuxMode: false,
      analysisMessage: "",              // This will store the text currently displayed.
      messageInterval: null,            // A separate interval to manage messages
      randomDocs: [],
    };
  },

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.stopProgressBar();
  },

  watch: {
    isAnalysing: {
      handler(newValue) {
        if (newValue === "pending") {
          this.startProgressBar();
          this.startMessageInterval();
        } else {
          this.quickCompleteAndStopProgressBar();
          this.stopMessageInterval();
        }
      },
      immediate: true,
    },
  },

  computed: {
    allowedContentieux() {
      const uid = auth.currentUser.uid;
      return uid === '4DiytWMEOQNQ0wAtXMOAso0aYMu1' || uid === 'nqOg063yiAQTQqFp4j8ZwKfD9L22';
    }
  },

  async mounted() {
    // Check analysis status and start listening to changes.
    await this.checkAnalysisStatus();
    if (this.isAnalysing === "pending") {
      this.startProgressBar();
    }
    this.listenToAnalysisStatus();

    try {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;

      // Fetch dossier name and context
      const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);
      const dossierDoc = await getDoc(dossierDocRef);
      if (dossierDoc.exists()) {
        console.log("Dossier data:", dossierDoc.data());
        this.dossierName = dossierDoc.data().name;
        this.searchQuery = dossierDoc.data().contexte || "";
        this.dossierType = dossierDoc.data().type || null;
        console.log('Dossier type:', this.dossierType);

        // If a questionId is provided, set searchQuery to the corresponding question's text
        if (this.questionId) {
          const selectedQuestion = this.questions.find(q => q.id === this.questionId);
          if (selectedQuestion) {
            this.searchQuery = selectedQuestion.text;
          } else {
            console.warn(`Question with ID "${this.questionId}" not found.`);
          }
        }

      } else {
        this.error = "Dossier non trouvé";
        return;
      }

      // Fetch cases in the dossier
      const casesRef = collection(db, "users", userId, "dossiers", dossierId, "cases");
      const casesSnapshot = await getDocs(casesRef);
      this.cases = casesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error fetching cases:", error);
      this.error = "Erreur lors de la récupération des décisions sauvegardées.";
    } finally {
      this.loading = false;
    }

    this.$nextTick(() => {
      if (this.$refs.searchBox) {
        this.$refs.searchBox.focus();
      }
    });

    this.$nextTick(() => {
      this.adjustTextareaHeight();
    });
  },

  methods: {
    handleAnalysisButtonClick() {
      if (this.isAnalysing === "pending") {
        alert("L'analyse est en cours. Veuillez patienter.");
      } else if (this.isAnalysing === "done") {
        this.openAnalysisModal();
      } else {
        this.showSyntheseModal = true;
      }
    },

    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    startMessageInterval() {
      const startTime = Date.now();

      // Clear any existing interval just to be safe
      if (this.messageInterval) {
        clearInterval(this.messageInterval);
        this.messageInterval = null;
      }

      this.messageInterval = setInterval(() => {
        // Compute elapsed time in seconds
        const elapsed = (Date.now() - startTime) / 1000;

        // Stop if analysis is done or no longer "pending"
        if (this.isAnalysing !== "pending") {
          this.stopMessageInterval();
          return;
        }

        // Now pick which message to display based on elapsed time
        if (elapsed < 5) {
          this.analysisMessage = "Démarrage de la recherche...";
        } else if (elapsed < 13) {
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[0]} documents...`;
        } else if (elapsed < 19) {
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[1]} documents...`;
        } else if (elapsed < 27) {
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[2]} documents...`;
        } else if (elapsed < 40) {
          this.analysisMessage = `Nos agents ont lu ${this.randomDocs[3]} documents...`;
        } else if (elapsed < 53) {
          this.analysisMessage = "Extraction d'informations pertinentes...";
        } else if (elapsed < 75) {
          this.analysisMessage = "Génération de réponse...";
        } else {
          this.analysisMessage = "Presque finis...";
        }
      }, 1000); // check every 1 second
    },

    stopMessageInterval() {
      if (this.messageInterval) {
        clearInterval(this.messageInterval);
        this.messageInterval = null;
      }
    },

    adjustTextareaHeight() {
      const textarea = this.$refs.searchBox;
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, this.$el.clientHeight)}px`;
    },

    goBack() {
      this.$router.go(-1);
    },

    goDashboard() {
      this.$router.push({
        name: 'DashboardPage',
      });
    },


    // async openAnalysisModal() {
    //   try {
    //     const userId = auth.currentUser.uid;
    //     const dossierId = this.$route.params.dossierId;

    //     const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);
    //     const dossierDoc = await getDoc(dossierDocRef);

    //     if (dossierDoc.exists()) {
    //       const pointsList = dossierDoc.data().points_list || [];
    //       if (pointsList.length === 0) {
    //         alert("Aucune synthèse disponible pour ce dossier.");
    //         return;
    //       }
    //       this.analyses = pointsList;
    //       this.showModal = true;
    //     } else {
    //       alert("Dossier non trouvé.");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching analysis:", error);
    //     alert("Erreur lors de la récupération de la synthèse.");
    //   }
    // },

    // Define the goToLibrary method to go to the examplePage
    goToLibrary() {
      this.$router.push("/examplePage");
    },

    async checkAnalysisStatus() {
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;

        const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);
        const dossierDoc = await getDoc(dossierDocRef);
        if (dossierDoc.exists()) {
          this.isAnalysing = dossierDoc.data().isAnalysing || null;
          this.analyses = dossierDoc.data().points_list || [];
        } else {
          this.isAnalysing = null;
        }
      } catch (error) {
        console.error("Error checking analysis status:", error);
        this.isAnalysing = null;
      }
    },

    toggleContentieux() {
      this.contentieuxMode = !this.contentieuxMode;
      console.log("Contentieux mode:", this.contentieuxMode);
    },

    startAnalysis(context, pointList) {
      console.log("Starting analysis with context:", context);

      this.randomDocs = [
        this.getRandomInt(45, 55),
        this.getRandomInt(130, 160),
        this.getRandomInt(250, 314),
        this.getRandomInt(350, 400),
      ];

      // Initialize the message to an empty string (or first message)
      this.analysisMessage = "";

      // Start the progress bar
      this.startProgressBar();

      // Also start the message interval
      this.startMessageInterval();

      if (!context.trim()) {
        alert("Veuillez fournir un contexte pour le dossier.");
        return;
      }
      console.log("Launching analysis...");
      this.isAnalysing = "pending";

      const dossierId = this.$route.params.dossierId;
      const userId = auth.currentUser.uid;
      const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);

      updateDoc(dossierDocRef, {
        contexte: context,
      });

      getFirebaseToken()
        .then(token => {
          if (!token) {
            alert("Erreur d'authentification. Veuillez réessayer.");
            return;
          }

          const mode = this.contentieuxMode ? "contentieux" : "analyse";

          axios.post(
            `https://api.query-juriste.com/copilot/get_analyse_fiscale`,
            {
              contexte: context,
              dossierId: dossierId,
              userId: userId,
              point_list: pointList,
              visas_list: [],
              mode: mode,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ).then(() => {
            this.$toast.success(
              "La synthèse a été lancée avec succès. Cela peut prendre plusieurs minutes."
            );
          }).catch(error => {
            console.error("Error starting analysis:", error);
            this.$toast.error("Erreur lors du lancement de la synthèse.");
          });
        })
        .catch(error => {
          console.error("Error fetching token:", error);
          this.$toast.error("Erreur lors de la récupération du token. Veuillez réessayer.");
        });
    },

    showAnalysisModal() {
      this.analyses = this.cases.map(caseItem => ({
        id: caseItem.id,
        title: caseItem.title,
        summary: `Analyse pour ${caseItem.title}`,
      }));
      this.showModal = true;
    },

    createLink(result) {
      if (result.ID && result.ID.includes("TEXT")) {
        return `https://www.legifrance.gouv.fr/ceta/id/${result.ID}`;
      } else {
        return `https://www.courdecassation.fr/decision/${result.ID_judi}`;
      }
    },

    toggleSummary(clickedCaseId) {
      if (this.activeCaseId === clickedCaseId) {
        this.activeCaseId = null;
      } else {
        this.activeCaseId = clickedCaseId;
        if (!this.caseSummaries[clickedCaseId]) {
          const docRef = doc(db, "cases", clickedCaseId);
          getDoc(docRef)
            .then(docSnap => {
              if (docSnap.exists()) {
                this.$set(this.caseSummaries, clickedCaseId, docSnap.data());
              } else {
                console.log("No case summary found for:", clickedCaseId);
              }
            })
            .catch(error => {
              console.error("Error fetching case summary:", error);
            });
        }
      }
    },

    openTextModal(caseId, title) {
      this.showTextModal = true;
      this.modalText = this.caseSummaries[caseId].texte;
      this.modalTitle = title;
    },

    closeTextModal() {
      this.showTextModal = false;
    },

    async removeCase(caseItem) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cette décision du dossier ?")) {
        try {
          const userId = auth.currentUser.uid;
          const dossierId = this.$route.params.dossierId;
          const caseDocRef = doc(db, "users", userId, "dossiers", dossierId, "cases", caseItem.id);
          await deleteDoc(caseDocRef);
          this.cases = this.cases.filter(c => c.id !== caseItem.id);
        } catch (error) {
          console.error("Error deleting case:", error);
          alert("Erreur lors de la suppression de la décision.");
        }
      }
    },

    startProgressBar() {
      this.progressPercentage = 0;

      const slower = (this.isAnalysing === "pending" && this.contentieuxMode);

      // Start an interval to update progress in steps
      this.progressInterval = setInterval(() => {
        // Determine increment based on current progress
        let increment;
        if (this.progressPercentage < 50) {
          increment = slower ? 0.08 : 0.10;   // Faster increment from 0% to 50%
        } else if (this.progressPercentage < 80) {
          increment = slower ? 0.04 : 0.04;   // Slower increment from 50% to 75%
        } else if (this.progressPercentage < 95) {
          increment = slower ? 0.016 : 0.018; // Even slower from 75% to 95%
        } else {
          increment = slower ? 0.008 : 0.008; // Slowest from 95% to 100%
        }

        // Update progress
        if (this.progressPercentage < 99) {
          this.progressPercentage += increment;
          if (this.progressPercentage >= 99) {
            this.progressPercentage = 99;
            this.stopProgressBar(); // Clear interval, etc.
          }
        } else {
          this.stopProgressBar();
        }
      }, 100); // Adjust interval speed as needed
    },

    quickCompleteAndStopProgressBar() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
        this.progressPercentage = 100;
        setTimeout(() => {
          this.progressPercentage = 0;
        }, 500);
      }
    },

    stopProgressBar() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    },

    listenToAnalysisStatus() {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;
      const dossierDocRef = doc(db, "users", userId, "dossiers", dossierId);

      this.unsubscribe = onSnapshot(
        dossierDocRef,
        async (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const previousStatus = this.isAnalysing;
            this.isAnalysing = data.isAnalysing;

            // Lorsque le statut passe de "pending" à "done"
            if (previousStatus === "pending" && this.isAnalysing === "done") {
              this.$toast.success("L'analyse est terminée. Vous pouvez maintenant consulter la synthèse.");

              this.dossierType = data.type || null;

              // Appeler adjustTextareaHeight pour ajuster la hauteur de la textbox
              this.$nextTick(() => {
                this.adjustTextareaHeight();
              });

              this.$nextTick(async () => {
                try {
                  if (data.type === "contentieux") {
                    console.log("Fetching contentieux data...");
                    await this.$refs.ContentieuxModal.fetchDossierData();
                    this.$refs.ContentieuxModal.createGraph();
                  } else {
                    console.log("Fetching analysis data...");
                    await this.$refs.analysisModal.fetchDossierData();
                    this.$refs.analysisModal.createGraph();
                  }
                } catch (err) {
                  console.error("Error fetching sub-collection data", err);
                }
              });
            }
          }
        },
        (error) => {
          console.error("Error listening to analysis status:", error);
        }
      );
    },
  },
};
</script>

<style scoped>
/* ----------------------------------------- */
/*  Same "shimmering" text effect           */
/* ----------------------------------------- */
.loading-message {
  color: transparent;
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 6s linear infinite;
  cursor: default;
  pointer-events: none;
}

/* The “sliding” effect for the gradient */
@keyframes slide {
  to {
    background-position: -100%;
  }
}

/* ----------------------------------------- */
/*   Centered title styling                 */
/* ----------------------------------------- */
.title-points {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* Add a new CSS rule to gray out the button when disabled */
.toggle-button-disabled {
  background-color: grey !important;
  pointer-events: none;
}

.toggle-button-none {
  display: none !important;
}

.search-form {
  width: 80%;
  background-color: #f4f4f4;
  border-radius: 15px;
}

.title-container {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, background-color 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.progress-bar-container {
  margin-top: 10px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.pending-analysis {
  background-color: white !important;
  color: #1c1c1a !important;
  cursor: not-allowed !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-input:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent; /* or a color of your choice */
}

.example-container {
  display: flex;
  justify-content: center;
}

.example-button {
  background-color: white;
  display: flex;
  color: black;
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.85rem;
  margin-top: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.example-button:hover {
  background-color: lightgray;
}

.enrichir-button {
  color: #f4f4f4;
  border: none;
  padding: 9px;
  border-radius: 4px;
  font-size: 0.7rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.enrichir-button:hover {
  background-color: #444;
}

.buttons-line {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#contentieux-trigger-container {
  margin: 0.5em;
}

#contentieux-trigger-button {
  margin-top: 0px;
  margin-bottom: 2px;
  padding: 10px;
  font-size: 0.8rem;
}

/* Button styling */
.toggle-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;            /* space between elements in the button */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 9999px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #f0f0f0;
}

/* The switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.toggle-button-active {
  color: #2196F3; /* same blue as the switch */
  border-color: #2196F3;
}

/* Hide the native checkbox visually */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

/* The slider itself */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

/* The circle inside the slider */
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

/* Slider color when checked */
input:checked + .slider {
  background-color: #2196F3;
}

/* Move the slider circle to the right when checked */
input:checked + .slider:before {
  transform: translateX(15px);
}

.buttons-container {
  margin-top: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 100%;
  min-height: 10vh;
  max-height: 50vh;
  padding: 8px;
  border-radius: 15px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  resize: none;
}

#error-input {
  border-color: red;
}

#action-container {
  padding: 20px;
  background-color: white;
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.main-container {
  display: flex;
  min-height: 250vh;
}

.page-header {
  background-color: #f4f4f4;
  height: 8.5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dossier-page {
  height: 100vh;
  font-family: "Roboto", sans-serif;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.loading {
  font-size: 1.2em;
}

.error {
  color: red;
}

.search-results {
  width: 50%;
  margin: 20px;
}

.result-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.result-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.case-details p {
  margin-bottom: 10px;
}

.case-details {
  margin-top: 20px;
}

.back-button {
  margin-top: 20px;
  background-color: #1c1c1a;
  color: #f4f4f4;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #444;
}

.graph {
  width: 95%;
  margin-top: 15vh;
}

.question-id-info {
  background-color: #eee;
  padding: 10px;
  margin-bottom: 20px;
}
</style>
