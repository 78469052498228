<template>
  <div class = "main-container">
    <header class="dashboard-header">
      <!-- "Retour" button -->
      <button style="display: flex;" @click="goToSearchPage">
        <img src="@/assets/logo-query-juriste-wq.png" alt="logo" class="logo-image" />
      </button>

      <h1>Guide d'utilisation - Query Juriste</h1>
    </header>
    <div class="page-container">
      <div class="sidebar">
        <!-- "Recherche +" button -->
        <button class="sidebar-button" @click="goToSearchPage">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="black" class="bi bi-plus-circle" viewBox="0 0 16 16" style="margin-right: 6px; margin-top: 1px;">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
          </svg>
          Recherche
        </button>

        <!-- "Nous contacter" button -->
        <button @click="goToContactPage" class="sidebar-button">
          <div class="button-contact" style="display: flex">
            <!-- Envelope Icon -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-envelope"
              viewBox="0 0 16 16"
              style="margin-top: 6px; margin-right: 5px;"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v0.5l-8 5-8-5V4z"/>
              <path d="M0 5.5l8 5 8-5V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.5z"/>
            </svg>
            Nous contacter
          </div>
        </button>
      </div>

      <div class="dashboard-container">
        <!-- Guide Section -->
        <section class="guide-section">
          <p>
            Ce guide s'adresse aux utilisateurs avancés, pour exploiter pleinement les capacités de Query Juriste.
            Cet outil repose sur des agents intelligents conçus pour être déployés au sein d'une base de données
            juridique exhaustive, afin d’analyser des problématiques juridiques et de fournir des réponses approfondies.
          </p>

          <h3>Options d'utilisation de l'outil :</h3>
          <p class="guide-intro">
            Nous allons explorer les différentes manières d'utiliser Query Juriste :
            la <strong>formulation d'une question de droit précise</strong>, l’<strong>exploration à partir de mots-clés</strong>,
            et la <strong>contextualisation d’une situation juridique</strong>. Chacune de ces options permet d'exploiter
            au mieux l'intelligence de l'outil pour des analyses approfondies.
          </p>

          <!-- *** MODIF *** -->
          <!-- Bloc n°1 : "1. Formuler une question de droit précise" avec toggle -->
          <div class="toggle-usage-section">
            <div class="toggle-header" @click="toggleUsageSection('questionDroit')">
              <button
                class="toggle-button"
                :class="{ 'rotated': usageSectionsVisibility.questionDroit }"
                aria-label="Toggle Usage Section"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6
                       6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293
                       8 4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </button>
              <h4 style="margin-left: 5px;">1. Formuler une question de droit précise</h4>
            </div>
            <div v-if="usageSectionsVisibility.questionDroit" class="toggle-content">
              <p>
                Il est évidemment possible de poser une question juridique directe à Query Juriste, comme point de départ
                naturel pour votre analyse. Si vous avez identifié n’importe quelle question juridique, Query Juriste peut
                élargir votre champ d'analyse en produisant une série de questions connexes, et finalement donner une
                réponse à votre question. Pour cela :
              </p>
              <ul>
                <li>Précisez votre question de droit.</li>
                <li>
                  L’IA décortique votre question et génère des angles supplémentaires d’analyse, mettant en évidence
                  des dimensions inattendues ou encore sous-explorées.
                </li>
              </ul>

              <div class="exemple-div">
                <p><strong>Exemple pratique</strong></p>
                <p>
                  Un organisme à but non lucratif partiellement soumis à l'IS peut-il déduire des amortissements?
                </p>
                <p><em><strong>Questions générées :</strong></em></p>
                <ul>
                  <li>
                    Un organisme à but non lucratif (OBNL) peut-il être soumis à l'impôt sur les sociétés (IS) ?
                  </li>
                  <li>
                    Dans le cas d'une OBNL partiellement soumise à l'IS, quelles sont les activités ou les revenus qui
                    entrent dans le champ d'application de l'IS ?
                  </li>
                  <li>
                    Les amortissements sont-ils déductibles des revenus soumis à l'IS pour une OBNL, et si oui, selon quelles
                    modalités ?
                  </li>
                  <li>
                    Existe-t-il des spécificités pour la déduction des amortissements dans le cas d'une OBNL partiellement
                    soumise à l'IS, par rapport à une société commerciale classique ?
                  </li>
                </ul>
              </div>

              <p>
                Query Juriste déploiera des agents qui, pendant quelques minutes, navigueront dans notre base de données
                internes, liront des milliers de documents, et fourniront ensuite une réponse d'une exhaustivité inégalée.
                Après avoir adressé chaque question de droit, l'IA fournira une réponse globale à la question initiale.
                (À venir)
              </p>

              <p>
                Cette approche vise à favoriser une compréhension globale et contextuelle du cadre juridique, indispensable
                dans une démarche de recherche approfondie.
              </p>
            </div>
          </div>

          <!-- *** MODIF *** -->
          <!-- Bloc n°2 : "2. Explorer à partir de mots-clés" avec toggle -->
          <div class="toggle-usage-section">
            <div class="toggle-header" @click="toggleUsageSection('explorerMotsCles')">
              <button
                class="toggle-button"
                :class="{ 'rotated': usageSectionsVisibility.explorerMotsCles }"
                aria-label="Toggle Usage Section"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6
                       6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293
                       8 4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </button>
              <h4 style="margin-left: 5px;">2. Explorer à partir de mots-clés</h4>
            </div>
            <div v-if="usageSectionsVisibility.explorerMotsCles" class="toggle-content">
              <p>
                Dans une démarche exploratoire ou face à une pénurie d’idées précises, les mots-clés deviennent un outil
                puissant. Voici comment les utiliser eﬃcacement :
              </p>
              <ul>
                <li>Fournissez des mots-clés représentatifs de votre thématique.</li>
                <li>
                  Query Juriste transformera ces mots-clés en une série de questions d’analyse, vous offrant une base
                  de réflexion enrichie.
                </li>
              </ul>

              <div class="exemple-div">
                <p><strong>Exemple pratique</strong></p>
                <p>
                  <em>Mots-clés :</em> Crédit d'impôt, Éligibilité écologique, Compensation carbone
                </p>
                <p><em><strong>Questions générées :</strong></em></p>
                <ul>
                  <li>
                    Quelles sont les conditions d'éligibilité aux crédits d'impôt relatifs aux dépenses écologiques en droit
                    fiscal français, notamment en matière de compensation carbone ?
                  </li>
                  <li>
                    Existe-t-il des conventions fiscales internationales impactant l'application des crédits d'impôt pour
                    des projets de compensation carbone réalisés à l'étranger par des résidents fiscaux français ?
                  </li>
                  <li>
                    Comment la jurisprudence (nationale et européenne) interprète-t-elle la notion de « compensation
                    carbone » et son éligibilité aux différents dispositifs de crédits d'impôt écologiques ?
                  </li>
                  <li>
                    Quels sont les mécanismes de contrôle et les sanctions applicables en cas de fraude ou d'abus dans le
                    cadre de la déclaration et de l'obtention de crédits d'impôt liés à la compensation carbone ?
                  </li>
                </ul>
              </div>

              <p>
                Encore une fois, l’IA va adresser ces questions de manière exhaustive, puis fournira ensuite une
                réponse globale et contextuelle, consolidant toutes les perspectives abordées. Cette fonctionnalité peut
                servir de tremplin pour structurer une recherche ou délimiter des champs d’étude pertinents.
              </p>
            </div>
          </div>

          <!-- *** MODIF *** -->
          <!-- Bloc n°3 : "3. Contextualiser une situation juridique" avec toggle -->
          <div class="toggle-usage-section">
            <div class="toggle-header" @click="toggleUsageSection('contextualiserSituation')">
              <button
                class="toggle-button"
                :class="{ 'rotated': usageSectionsVisibility.contextualiserSituation }"
                aria-label="Toggle Usage Section"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6
                       6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293
                       8 4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </button>
              <h4 style="margin-left: 5px;">3. Contextualiser une situation juridique</h4>
            </div>
            <div v-if="usageSectionsVisibility.contextualiserSituation" class="toggle-content">
              <p>
                Pour les avocats et juristes qui travaillent sur des cas pratiques ou des situations contentieuses, fournir
                un contexte riche et détaillé permet à Query Juriste de produire des réponses adaptées.
              </p>
              <ul>
                <li>
                  Détaillez le contexte factuel (ex. : antécédents, enjeux économiques…).
                </li>
                <li>
                  Incluez des éléments spécifiques tels que le type de contentieux, les arguments déjà avancés, ou encore
                  les éléments doctrinaux ou jurisprudentiels pertinents.
                </li>
              </ul>
              <p>
                En fonction de la richesse du contexte fourni, l’IA est capable de mettre en évidence des questions
                juridiques sous-jacentes et de proposer des pistes d’analyse approfondies.
              </p>
              <p>
                Plus le contexte est riche et détaillé, mieux l’IA pourra identifier des aspects cruciaux ou inexplorés.
                N’hésitez pas à fournir un maximum de détails, même si cela semble excessif : chaque élément peut
                contribuer à une analyse plus précise et pertinente.
              </p>

              <div class="exemple-div">
                <p><strong>Exemple pratique</strong></p>
                <p>
                  Contexte : M. Pierre Dupont, entrepreneur dans le secteur de la rénovation immobilière, possède plusieurs biens situés en région
                  parisienne. L’un d’eux, un immeuble ancien situé dans le centre de Paris, a été visé par une enquête judiciaire portant sur un réseau
                  soupçonné d’utiliser l’investissement immobilier à des fins de blanchiment d’argent.
                  <br /><br />
                  Lors d’une perquisition menée dans ce cadre, les enquêteurs ont relevé que l’immeuble en question avait été acquis en partie grâce à des
                  fonds dont l’origine semble douteuse...
                </p>
                <p><em><strong>Questions générées :</strong></em></p>
                <ul>
                  <li>
                    Une administration fiscale peut-elle imposer un bien immobilier comme revenu...
                  </li>
                  <li>
                    Quelle est la charge de la preuve en matière d'imposition...
                  </li>
                  <li>
                    L'imposition d'un bien immobilier comme revenu sur la base de soupçons non confirmés...
                  </li>
                </ul>
              </div>
              <p>
                En résumé, qu’il s’agisse de poser une question, d’approfondir un cas, ou d’explorer un sujet à
                partir de mots-clés, cet outil offre une analyse rigoureuse et contextuelle pour soutenir vos
                travaux de recherche.
              </p>
            </div>
          </div>
        </section>

        <!-- *** MODIF *** -->
        <!-- Ajout d’un <h3> avant la section des exemples pratiques -->
        <h3 class="title-example">Exemples pratiques par domaine</h3>

        <!-- Categories Section (Exemples pratiques) -->
        <section class="categories-section">
          <div v-for="category in categorizedQuestions" :key="category.name" class="category-group">

            <!-- Category Header with Toggle -->
            <div class="category-header" @click="toggleCategory(category.name)">
              <button
                class="toggle-category-button"
                :class="{ 'rotated': isCategoryVisible(category.name) }"
                aria-label="Toggle Category"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1
                      0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8
                      4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </button>
              <h2 style="margin-left: 5px">{{ category.name }}</h2>
            </div>

            <!-- Questions List within the Category -->
            <ul v-if="isCategoryVisible(category.name)" class="questions-list">
              <li v-for="question in category.questions" :key="question.id">
                <!-- The entire card is clickable. Clicking it creates a dossier and navigates to DossierPage -->
                <div class="question-item" @click="createAndOpenDossier(question.id)">
                  <div class="question-header">
                    <span class="question-text">{{ question.text }}</span>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "@/firebase/firebaseConfig";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

export default {
  name: 'examplePage',
  data() {
    return {
      selectedQuestion: null,
      dossiers: [],
      specificationsVisibility: {},
      categoriesVisibility: {},

      // *** MODIF *** Définition de la visibilité des 3 sections d'utilisation
      usageSectionsVisibility: {
        questionDroit: false,
        explorerMotsCles: false,
        contextualiserSituation: false
      },


      // Questions list as provided
      questions: [
        // Droit des affaires
        {
          id: 'rupture_contrat_distribution',
          text: 'Une entreprise souhaite rompre un contrat de distribution exclusive avant son terme en invoquant une faute grave du distributeur. Quels sont les critères pour établir cette faute grave et éviter une indemnisation ?',
          specifications: [
            {
              id: 'droit_affaires_moyenne',
              title: 'Droit des affaires - Question contextuelle moyenne :',
              description: 'Une entreprise souhaite rompre un contrat de distribution exclusive avant son terme en invoquant une faute grave du distributeur. Quels sont les critères pour établir cette faute grave et éviter une indemnisation ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'abus_position_dominante',
          text: 'Une société française exporte régulièrement ses produits vers l\'Union européenne. Elle a récemment été accusée d\'abus de position dominante par un concurrent, qui soutient qu\'elle impose des prix prédateurs à ses clients dans certains États membres. Quels éléments de preuve et critères juridiques le concurrent devra-t-il apporter pour que cette accusation soit fondée selon le droit de la concurrence européen ?',
          specifications: [
            {
              id: 'droit_affaires_longue',
              title: 'Droit des affaires - Question longue et détaillée :',
              description: 'Une société française exporte régulièrement ses produits vers l\'Union européenne. Elle a récemment été accusée d\'abus de position dominante par un concurrent, qui soutient qu\'elle impose des prix prédateurs à ses clients dans certains États membres. Quels éléments de preuve et critères juridiques le concurrent devra-t-il apporter pour que cette accusation soit fondée selon le droit de la concurrence européen ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'conflit_earn_out_LBO',
          text: 'Une société de capital-investissement acquiert une entreprise familiale via un LBO (leveraged buyout). Après la transaction, un conflit émerge avec les actionnaires fondateurs concernant les modalités de calcul de l\'earn-out prévu dans le contrat. Quels sont les leviers juridiques pour trancher ce litige et protéger les intérêts de l’acquéreur ?',
          specifications: [
            {
              id: 'droit_affaires_complexe',
              title: 'Droit des affaires - Cas complexe et très contextuel :',
              description: 'Une société de capital-investissement acquiert une entreprise familiale via un LBO (leveraged buyout). Après la transaction, un conflit émerge avec les actionnaires fondateurs concernant les modalités de calcul de l\'earn-out prévu dans le contrat. Quels sont les leviers juridiques pour trancher ce litige et protéger les intérêts de l’acquéreur ?'
            }
          ],
          category: 'Droit des affaires'
        },
        {
          id: 'litige_confidentialite_startup',
          text: 'Une startup technologique a récemment obtenu un financement important auprès de plusieurs investisseurs en capital-risque. Cependant, l’un des investisseurs souhaite désormais se retirer, invoquant une violation de la clause de confidentialité dans le pacte d’actionnaires, après qu’une discussion stratégique ait été relayée dans la presse. Quels mécanismes contractuels et juridiques peuvent être utilisés pour résoudre ce litige, tout en préservant la stabilité du tour de financement et des relations entre les parties ?',
          specifications: [
            {
              id: 'droit_affaires_pratique',
              title: 'Droit des affaires - Cas pratique structuré et multidimensionnel :',
              description: 'Une startup technologique a récemment obtenu un financement important auprès de plusieurs investisseurs en capital-risque. Cependant, l’un des investisseurs souhaite désormais se retirer, invoquant une violation de la clause de confidentialité dans le pacte d’actionnaires, après qu’une discussion stratégique ait été relayée dans la presse. Quels mécanismes contractuels et juridiques peuvent être utilisés pour résoudre ce litige, tout en préservant la stabilité du tour de financement et des relations entre les parties ?'
            }
          ],
          category: 'Droit des affaires'
        },

        // Droit fiscal
        {
          id: 'credit_impot_recherche',
          text: 'Une entreprise souhaite bénéficier d\'un crédit d\'impôt pour dépenses de recherche (CIR). Quels types de dépenses sont éligibles et comment doivent-elles être justifiées auprès de l\'administration fiscale ?',
          specifications: [
            {
              id: 'droit_fiscal_moyenne',
              title: 'Droit fiscal - Question contextuelle moyenne :',
              description: 'Une entreprise souhaite bénéficier d\'un crédit d\'impôt pour dépenses de recherche (CIR). Quels types de dépenses sont éligibles et comment doivent-elles être justifiées auprès de l\'administration fiscale ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'plus_value_immobilier',
          text: 'Un contribuable personne physique a réalisé une plus-value importante suite à la vente d’un bien immobilier non affecté à son habitation principale. Quels sont les abattements applicables pour durée de détention, et comment est calculée l\'imposition sur cette plus-value dans le cadre de l\'impôt sur le revenu et des prélèvements sociaux ?',
          specifications: [
            {
              id: 'droit_fiscal_longue',
              title: 'Droit fiscal - Question longue et détaillée :',
              description: 'Un contribuable personne physique a réalisé une plus-value importante suite à la vente d’un bien immobilier non affecté à son habitation principale. Quels sont les abattements applicables pour durée de détention, et comment est calculée l\'imposition sur cette plus-value dans le cadre de l\'impôt sur le revenu et des prélèvements sociaux ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'depenses_somptuaires_pme',
          text: 'Une PME française ayant opté pour le régime réel simplifié a récemment fait l\'objet d\'une vérification de comptabilité. L\'administration fiscale conteste l\'inscription de certaines charges en les qualifiant de dépenses somptuaires non déductibles. Quels critères permettent de déterminer si une dépense est déductible, et quels recours l’entreprise peut-elle engager face à une rectification fiscale ?',
          specifications: [
            {
              id: 'droit_fiscal_complexe',
              title: 'Droit fiscal - Cas complexe et très contextuel :',
              description: 'Une PME française ayant opté pour le régime réel simplifié a récemment fait l\'objet d\'une vérification de comptabilité. L\'administration fiscale conteste l\'inscription de certaines charges en les qualifiant de dépenses somptuaires non déductibles. Quels critères permettent de déterminer si une dépense est déductible, et quels recours l’entreprise peut-elle engager face à une rectification fiscale ?'
            }
          ],
          category: 'Droit fiscal'
        },
        {
          id: 'transmission_parts_sociales',
          text: 'Une société familiale exploitant un commerce souhaite transmettre ses parts sociales à la génération suivante tout en limitant l’impact fiscal de cette opération. Quels mécanismes, tels que le pacte Dutreil, peuvent être utilisés pour réduire les droits de mutation, et quelles sont les conditions juridiques et fiscales à respecter pour en bénéficier ?',
          specifications: [
            {
              id: 'droit_fiscal_pratique',
              title: 'Droit fiscal - Cas pratique structuré et multidimensionnel :',
              description: 'Une société familiale exploitant un commerce souhaite transmettre ses parts sociales à la génération suivante tout en limitant l’impact fiscal de cette opération. Quels mécanismes, tels que le pacte Dutreil, peuvent être utilisés pour réduire les droits de mutation, et quelles sont les conditions juridiques et fiscales à respecter pour en bénéficier ?'
            }
          ],
          category: 'Droit fiscal'
        },

        // Droit de la famille
        {
          id: 'prestation_compensatoire',
          text: 'Un couple marié sous le régime de la séparation de biens souhaite divorcer. L’un des conjoints réclame une prestation compensatoire. Quels éléments seront pris en compte par le juge pour décider de l’attribution et du montant de cette prestation ?',
          specifications: [
            {
              id: 'droit_famille_moyenne',
              title: 'Droit de la famille - Question contextuelle moyenne :',
              description: 'Un couple marié sous le régime de la séparation de biens souhaite divorcer. L’un des conjoints réclame une prestation compensatoire. Quels éléments seront pris en compte par le juge pour décider de l’attribution et du montant de cette prestation ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'demenagement_parent',
          text: 'Deux parents divorcés partagent l\'autorité parentale d\'un enfant mineur. L\'un des parents souhaite déménager dans une autre région pour des raisons professionnelles, ce qui impacterait les modalités de garde. Quels sont les recours juridiques de l\'autre parent pour s’opposer à ce déménagement et maintenir un droit de visite régulier ?',
          specifications: [
            {
              id: 'droit_famille_longue',
              title: 'Droit de la famille - Question longue et détaillée :',
              description: 'Deux parents divorcés partagent l\'autorité parentale d\'un enfant mineur. L\'un des parents souhaite déménager dans une autre région pour des raisons professionnelles, ce qui impacterait les modalités de garde. Quels sont les recours juridiques de l\'autre parent pour s’opposer à ce déménagement et maintenir un droit de visite régulier ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'indemnisation_maison_separation',
          text: 'Un couple non marié s’est séparé après 10 ans de vie commune. Ils ont acheté une maison ensemble, mais seul l’un d’entre eux figure sur l’acte de propriété. Après leur séparation, l’autre demande une indemnisation pour avoir contribué au remboursement du crédit et à l’entretien de la maison. Quels sont les moyens juridiques pour établir une compensation financière dans ce cas ?',
          specifications: [
            {
              id: 'droit_famille_complexe',
              title: 'Droit de la famille - Cas complexe et très contextuel :',
              description: 'Un couple non marié s’est séparé après 10 ans de vie commune. Ils ont acheté une maison ensemble, mais seul l’un d’entre eux figure sur l’acte de propriété. Après leur séparation, l’autre demande une indemnisation pour avoir contribué au remboursement du crédit et à l’entretien de la maison. Quels sont les moyens juridiques pour établir une compensation financière dans ce cas ?'
            }
          ],
          category: 'Droit de la famille'
        },
        {
          id: 'dettes_communautaires_divorce',
          text: 'Une femme découvre que son époux, avec lequel elle est mariée sous le régime de la communauté, a contracté plusieurs dettes importantes sans son consentement dans le cadre de son activité professionnelle. Suite à une procédure de divorce, elle s’inquiète des conséquences de ces dettes sur son patrimoine personnel. Quels sont les mécanismes de protection prévus par le droit pour limiter l’impact de ces dettes sur sa part de la communauté ? Quelles options s’offrent à elle pour contester sa responsabilité dans le remboursement de ces dettes ?',
          specifications: [
            {
              id: 'droit_famille_pratique',
              title: 'Droit de la famille - Cas pratique structuré et multidimensionnel :',
              description: 'Une femme découvre que son époux, avec lequel elle est mariée sous le régime de la communauté, a contracté plusieurs dettes importantes sans son consentement dans le cadre de son activité professionnelle. Suite à une procédure de divorce, elle s’inquiète des conséquences de ces dettes sur son patrimoine personnel. Quels sont les mécanismes de protection prévus par le droit pour limiter l’impact de ces dettes sur sa part de la communauté ? Quelles options s’offrent à elle pour contester sa responsabilité dans le remboursement de ces dettes ?'
            }
          ],
          category: 'Droit de la famille'
        },

        // Droit du travail
        {
          id: 'heures_sup_non_remunerees',
          text: 'Un salarié réclame le paiement d\'heures supplémentaires non rémunérées, mais l\'employeur conteste leur réalisation. Quels éléments le salarié doit-il apporter pour prouver qu’il a effectivement effectué ces heures ?',
          specifications: [
            {
              id: 'droit_travail_moyenne',
              title: 'Droit du travail - Question contextuelle moyenne :',
              description: 'Un salarié réclame le paiement d\'heures supplémentaires non rémunérées, mais l\'employeur conteste leur réalisation. Quels éléments le salarié doit-il apporter pour prouver qu’il a effectivement effectué ces heures ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'clause_non_concurrence',
          text: 'Un employeur souhaite mettre en place une clause de non-concurrence pour certains salariés après leur départ. Quels sont les critères de validité de cette clause en droit du travail français, et quelles conséquences en cas de non-respect par l\'employeur ?',
          specifications: [
            {
              id: 'droit_travail_longue',
              title: 'Droit du travail - Question longue et détaillée :',
              description: 'Un employeur souhaite mettre en place une clause de non-concurrence pour certains salariés après leur départ. Quels sont les critères de validité de cette clause en droit du travail français, et quelles conséquences en cas de non-respect par l\'employeur ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'licenciement_apres_burn_out',
          text: 'Un salarié a été placé en arrêt maladie pendant plusieurs mois suite à un burn-out. Pendant cette période, l’employeur a réorganisé l’entreprise, supprimant le poste du salarié pour motif économique. Ce dernier conteste son licenciement, estimant que l’employeur a abusé de la situation de fragilité liée à sa maladie. Quels sont les risques juridiques pour l’employeur, et comment justifier la réalité et le sérieux du motif économique ?',
          specifications: [
            {
              id: 'droit_travail_complexe',
              title: 'Droit du travail - Cas complexe et très contextuel :',
              description: 'Un salarié a été placé en arrêt maladie pendant plusieurs mois suite à un burn-out. Pendant cette période, l’employeur a réorganisé l’entreprise, supprimant le poste du salarié pour motif économique. Ce dernier conteste son licenciement, estimant que l’employeur a abusé de la situation de fragilité liée à sa maladie. Quels sont les risques juridiques pour l’employeur, et comment justifier la réalité et le sérieux du motif économique ?'
            }
          ],
          category: 'Droit du travail'
        },
        {
          id: 'reorganisation_temps_travail',
          text: 'Une entreprise souhaite modifier l’organisation du temps de travail en passant d’un horaire fixe à une annualisation du temps de travail pour ses employés. Certains salariés refusent cette modification, arguant qu\'elle change substantiellement leur contrat de travail. Quels sont les moyens légaux pour l’employeur de mettre en place cette réorganisation, tout en respectant les droits des salariés et les obligations conventionnelles ? Quels recours ces derniers peuvent-ils engager en cas de désaccord ?',
          specifications: [
            {
              id: 'droit_travail_pratique',
              title: 'Droit du travail - Cas pratique structuré et multidimensionnel :',
              description: 'Une entreprise souhaite modifier l’organisation du temps de travail en passant d’un horaire fixe à une annualisation du temps de travail pour ses employés. Certains salariés refusent cette modification, arguant qu\'elle change substantiellement leur contrat de travail. Quels sont les moyens légaux pour l’employeur de mettre en place cette réorganisation, tout en respectant les droits des salariés et les obligations conventionnelles ? Quels recours ces derniers peuvent-ils engager en cas de désaccord ?'
            }
          ],
          category: 'Droit du travail'
        }
      ]
    };
  },
  computed: {
    // Compute categorized questions for easier rendering
    categorizedQuestions() {
      const categories = {};

      this.questions.forEach(question => {
        if (!categories[question.category]) {
          categories[question.category] = [];
        }
        categories[question.category].push(question);
      });

      // Convert the categories object into an array for v-for
      return Object.keys(categories).map(categoryName => ({
        name: categoryName,
        questions: categories[categoryName]
      }));
    }
  },
  created() {
    // Initialize specifications visibility for all questions
    this.questions.forEach(q => {
      this.$set(this.specificationsVisibility, q.id, false);
    });

    // Initialize categories visibility (all expanded by default)
    this.categorizedQuestions.forEach(cat => {
      this.$set(this.categoriesVisibility, cat.name, false);
    });
  },
  methods: {
    // Toggle specifications visibility for a specific question
    toggleSpecifications(questionId) {
      this.specificationsVisibility[questionId] = !this.specificationsVisibility[questionId];
    },
    isSpecificationsVisible(questionId) {
      return this.specificationsVisibility[questionId];
    },

    goBack() {
      this.$router.go(-1);
    },

    // Toggle visibility for a specific category
    toggleCategory(categoryName) {
      this.categoriesVisibility[categoryName] = !this.categoriesVisibility[categoryName];
    },
    isCategoryVisible(categoryName) {
      return this.categoriesVisibility[categoryName];
    },

    // Optional: Get question text (if needed elsewhere)
    getQuestionText(questionId) {
      const question = this.questions.find(q => q.id === questionId);
      return question ? question.text : '';
    },

    toggleUsageSection(sectionKey) {
      this.usageSectionsVisibility[sectionKey] = !this.usageSectionsVisibility[sectionKey];
    },

    // Create a dossier and navigate to the dossier page
    async createAndOpenDossier(questionId) {
      try {
        const user = auth.currentUser;
        if (!user) {
          alert('Veuillez vous connecter pour créer un dossier.');
          return;
        }

        const userId = user.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        const newDossier = {
          name: 'Nouveau dossier',
          createdAt: serverTimestamp()
        };

        const docRef = await addDoc(dossiersRef, newDossier);
        this.dossiers.unshift({ id: docRef.id, ...newDossier });

        // Use this.$router since we are in Vue 2 Options API
        this.$router.push({
          name: 'DossierPage',
          params: {
            dossierId: docRef.id,
            questionId: questionId || undefined
          }
        });
      } catch (error) {
        console.error('Error creating dossier:', error);
        alert('Erreur lors de la création du dossier.');
      }
    },

    goToContactPage() {
      // You can adjust this route name/path to your actual contact page
      this.$router.push({ name: "ContactPage" });
    },

    async goToSearchPage() {
      // Check subscription status
      try {
        const userId = auth.currentUser.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');

        // Create new dossier with a 'createdAt' field
        const newDossier = {
          name: 'Nouveau dossier',
          createdAt: serverTimestamp(),  // Adds the timestamp of creation
          // Include any other default fields if necessary
        };

        // Add the dossier to Firestore
        const docRef = await addDoc(dossiersRef, newDossier);

        // Optionally update the local dossiers array
        this.dossiers.unshift({ id: docRef.id, ...newDossier });

        // Navigate to the dossier page
        this.$router.push({ name: 'DossierPage', params: { dossierId: docRef.id } });
      } catch (error) {
        console.error('Error creating dossier:', error);
        alert('Erreur lors de la création du dossier.');
      }
    },


  }
};
</script>

<style scoped>

.title-example {
  align-self: center;
  margin: 1em;
  color: #333;
  font-size: 1.8em;
  font-weight: 600;
}

.toggle-usage-section {
  margin-bottom: 1.5em;
}

.toggle-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 0.5px solid #d3d3d3;
  padding: 10px 15px;
  border-radius: 5px;
}

.toggle-header:hover {
  background-color: #eee;
}

.toggle-button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
}

.toggle-button .bi-chevron-right {
  transition: transform 0.3s ease;
}

.toggle-button.rotated .bi-chevron-right {
  transform: rotate(90deg);
}

.toggle-content {
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

/* Layout container for sidebar + main content */
.page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* Sidebar styles */
.sidebar {
  /* Make it sticky */
  position: sticky;
  top: 4em;
  align-self: flex-start;

  /* Set a height so that it can actually stick */
  height: 100vh;
  overflow-y: auto;

  width: 35%;
  background-color: white;
  border-right: 1px solid #e0e0e0;
  padding: 1em;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.sidebar-button {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  color: black;
  font-size: 1em;
  padding: 10px 20px;
  margin-top: 1em;
  width: 100%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.sidebar-button:hover {
  background-color: #e2e2e2;
  transform: translateX(5px);
}

/* Main content container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  padding: 30px;
  max-width: 1200px; /* optional max-width */
  margin: 0 auto;
}

/* Sticky header */
.dashboard-header {
  position: sticky;
  height: 4em;
  top: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-image {
  height: 40px;
  margin-right: 20px;
  /* adjust to your liking */
}

.dashboard-header h1 {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  flex: 1; /* push the "Retour" button to the left, title in center */
  /* If you want the header items spaced differently, adjust flex. */
}

/* Guide Section Enhancements */
.guide-section {
  display: flex;
  flex-direction: column;

  /* Add some padding and background for visual separation */
  padding: 2em;
  background-color: #fafafa;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 2em;
}

.guide-section h3 {
  margin-top: 1em;
  color: #333;
  font-size: 1.8em; /* Increase size */
  font-weight: 600;
  margin-bottom: 0.5em;
}

.guide-section h4 {
  margin-top: 0.4em;
  color: #333;
  font-size: 1.5em; /* Increase size */
  font-weight: 500;
  margin-bottom: 0.4em;
}

.guide-intro {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 1.5em;
  line-height: 1.4;
  font-style: italic;
}

/* Paragraph spacing */
.guide-section p {
  line-height: 1.6;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Lists in the guide section */
.guide-section ul {
  margin-left: 1.5em;
}

/* Spacing for list items */
.guide-section li {
  margin: 0.8em 0;
}

/* Example box styling */
.exemple-div {
  align-self: center;
  padding: 1.5em;
  font-style: italic;
  border-radius: 8px;
  font-size: 0.9em;
  width: 70%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
}

.categories-section {
  padding-bottom: 20px;
  margin-bottom: 30px;
}

/* Category Blocks */
.category-group {
  margin-bottom: 30px;
}

.category-header {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 0.5px solid #d3d3d3;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
}

.category-header h2 {
  margin: 0;
  font-size: 1.5em;
}

/* Toggle button */
.toggle-category-button {
  background: transparent;
  border: none;
  color: #007BFF;
  cursor: pointer;
  font-size: 1em;
  text-decoration: underline;
}

.toggle-category-button:hover {
  color: #0056b3;
}

/* Chevron rotation */
.toggle-category-button .bi-chevron-right {
  transition: transform 0.3s ease;
}

.toggle-category-button.rotated .bi-chevron-right {
  transform: rotate(90deg);
}

/* Questions list */
.questions-list {
  list-style-type: none;
  padding: 10px 0 0 0;
  margin: 0;
}

.question-item {
  display: block;
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 0.5px solid #d3d3d3;
  border-radius: 8px;
  color: #000;
  transition: background 0.3s;
  cursor: pointer;
  font-style: italic;
  font-weight: 400;
}

.question-item:hover {
  background: #f2f2f2;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  flex: 1;
}
</style>
